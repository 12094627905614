import React, { useEffect, useMemo, useState } from "react";
import { Image, Tooltip, Popover, Badge } from "@arco-design/web-react";
import "./index.less";
import TaskList from "./component/MaterialList";
import { clear_icon } from "@/assets/taskcenter";
import { task_file_b_icon, task_file_icon } from "@/assets/headimage";
import { fileUploadStore } from "@/stores/UploadStroe";
import { observer } from "mobx-react";
import { empty_icon } from "@/assets/workbenchimage";
import { toJS } from "mobx";
import dayjs from "dayjs";
import VideoRes from "@/stores/VideoResult";
import debounce from "lodash.debounce";
const MyTask: React.FC = () => {
  const [hoverTask, setHoverTask] = useState(task_file_icon);
  const [visible, setVisible] = React.useState(false);
  const [hiddenArray, setHiddenArray] = useState<string[]>([]);
  const handleBeforeUnload = (e) => {
    // 如果有未保存的更改或任务需要中断，显示提示
    if (
      !fileUploadStore.uploadTasks
        .map((v) => v.taskList)
        .every((subArray) =>
          subArray.every((item) => item.status !== "uploading"),
        )
    ) {
      e.preventDefault();

      e.returnValue = "您还有正在合成或上传的任务，关闭后任务将关闭"; // 这将显示浏览器默认的提示
      return "您还有正在合成或上传的任务，关闭后任务将关闭";
    }
    return undefined;
  };
  const handleStorageChange = debounce((event) => {
    if (event.key === "VideoResult") {
      window.location.reload();
    }
  }, 200);
  useEffect(() => {
    window.addEventListener("beforeunload", handleBeforeUnload);
    // window.addEventListener("storage", handleStorageChange);
    fileUploadStore.clearUploadTask();
    return () => {
      window.removeEventListener("beforeunload", handleBeforeUnload);
      // window.removeEventListener("storage", handleStorageChange);
    };
  }, []);

  useEffect(() => {
    VideoRes.getVideoResList(VideoRes.task_no);
  }, [visible]);
  const content = (
    <div className="my-task-box">
      <h1>我的任务</h1>
      {(fileUploadStore.uploadTasks && fileUploadStore.uploadTasks.length) ||
      (VideoRes.videoResList && VideoRes.videoResList.length) ? (
        <div>
          <div className="my-task-content">
            {[...fileUploadStore.uploadTasks, ...VideoRes.videoResList].map(
              (v, i) => {
                return (
                  <TaskList
                    key={i}
                    id={v.id}
                    type={v.type}
                    timer={dayjs(v.update_at).format("YYYY-MM-DD HH:mm:ss")}
                    setVisible={setVisible}
                    item={v.taskList}
                    onClick={() => {
                      fileUploadStore.deleteUploadTask(v.id);
                      VideoRes.filterTask_no(v.id);
                    }}
                  />
                );
              },
            )}
          </div>
          <div className="my-task-footer">
            <p>
              任务数:
              {[...fileUploadStore.uploadTasks, ...VideoRes.videoResList]
                .length - hiddenArray.length}
              /100
            </p>
            <p
              style={{ cursor: "pointer" }}
              onClick={() => {
                fileUploadStore.clearExamine();
                VideoRes.clearExamine();
              }}>
              <Image
                src={clear_icon}
                preview={false}
                width={16}
                style={{ marginTop: -4, marginRight: 4 }}
              />
              全部已读
            </p>
          </div>
        </div>
      ) : (
        <div className="task-empty-box">
          <Image
            src={empty_icon}
            preview={false}
            width={90}
            style={{ marginTop: -4, marginRight: 4 }}
          />
          <p>暂无记录</p>
        </div>
      )}
    </div>
  );

  return (
    <div className="popover-root">
      <Tooltip content="任务中心" className="task-tip">
        <Popover
          position="br"
          popupVisible={visible}
          onVisibleChange={setVisible}
          trigger="click"
          content={content}>
          <div className="my-task-root">
            <Badge
              count={Math.max(
                [
                  ...fileUploadStore.uploadTasks.filter((item) =>
                    item.taskList.some((v: any) => !v.isExamine),
                  ),
                  ...VideoRes.videoResList.filter((v) => !v.isExamine),
                ].length,
                0,
              )}>
              <img
                src={hoverTask}
                width={22}
                onMouseEnter={() => setHoverTask(task_file_b_icon)}
                onMouseLeave={() => setHoverTask(task_file_icon)}
              />
            </Badge>
          </div>
        </Popover>
      </Tooltip>
    </div>
  );
};

export default observer(MyTask);
