import React, { useState } from "react";
import "./index.less";
import {
  bulb,
  recommend,
  user,
  enterprise,
  recommend_bg,
  user_bg,
} from "@/assets/user-center";
import { Button } from "@arco-design/web-react";
import Enterprise from "./Enterprise";
import Person from "./Person";
import { IconLeft } from "@arco-design/web-react/icon";
interface IAttestationContentProps {
  setSteps: (opt: number) => void;
  handleChange: () => void;
}
const AttestationContent: React.FC<IAttestationContentProps> = (props) => {
  const { setSteps, handleChange } = props;
  const [isShow, setIsShow] = useState<boolean>(false);
  const [attestationType, setAttestationType] = useState("");
  return isShow ? (
    <>
      <p className="attestation-title">
        <IconLeft
          style={{ cursor: "pointer" }}
          onClick={() => {
            setIsShow(false);
            setAttestationType("");
          }}
        />
        &nbsp;
        {attestationType}认证
      </p>
      <div>
        {attestationType == "企业" ? (
          <Enterprise setSteps={setSteps} />
        ) : (
          <Person setSteps={setSteps} />
        )}
      </div>
    </>
  ) : (
    <>
      <div
        className={`attestation-root`}
        style={{
          transform: "",
        }}>
        <div className="msg-box">
          <img width={20} src={bulb} alt="" />
          <div>
            <p>
              根据《中华人民共和国网络安全法》及相关法律的规定，在开始使用您的账户前，需要进行实名认证，请根据协议要求，上传所需的实名认证资料和信息。
            </p>
          </div>
        </div>
        <div className="attestation-box">
          <div
            className={`${attestationType == "企业" ? "active-card" : ""}`}
            onClick={() => {
              setAttestationType("企业");
            }}>
            <div style={{ position: "absolute" }}>
              <img src={enterprise} width={22} alt="" />
              <p>企业认证</p>
            </div>
            <img src={recommend_bg} width="100%" alt="" />
            <img src={recommend} width={47} alt="" className="recommend" />
          </div>
          <div
            onClick={() => {
              setAttestationType("个人");
            }}
            className={`${attestationType == "个人" ? "active-card" : ""}`}>
            <div style={{ position: "absolute" }}>
              <img src={user} width={22} alt="" />
              <p>个人认证</p>
            </div>
            <img src={user_bg} width="100%" alt="" />
          </div>
        </div>
      </div>

      <div className="modal-footer">
        <Button
          onClick={() => {
            window.location.href = "/work-bench";
            handleChange();
          }}>
          暂不认证
        </Button>
        <Button
          disabled={!attestationType}
          type="primary"
          onClick={() => {
            setIsShow(!isShow);
          }}>
          下一步
        </Button>
      </div>
    </>
  );
};

export default AttestationContent;
