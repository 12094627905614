import ProjectNav from "@/pages/CreationSpace/CreativeSpace/Nav/ProjectNav";
import {
  Message,
  Modal,
  ModalProps,
  Pagination,
  Radio,
  Spin,
} from "@arco-design/web-react";
import { observer } from "mobx-react";
import MaterialContainer from "./MaterialContainer/MaterialContainer";
import { useEffect, useState } from "react";
import BatchCollection from "@/pages/CreationSpace/CreativeSpace/CreativeContainer/components/BatchAction/BatchCollection";
import LocalUpload from "./LocalUpload/LocalUpload";
import useNavProject from "@/useStore/createspace/useProject";
import { getMaterialUploadData } from "@/services/createSpace/graphQ/getMetarialList";
import useRequest from "@/requestHooks/mixCutDetail/useRequest";
import SimpleBream from "./SimpleBream/SimpleBream";
import { debounce } from "lodash";
import {
  IMaterialDetail,
  getSimpleMedia,
} from "@/services/createSpace/graphQ/getSimpleMedia";
import project_modal_icon from "@/assets/create-space/project-modal-icon.png";
import { IconClose } from "@arco-design/web-react/icon";
import "./MaterialFileModal.less";

interface IMaterialFileModalProps extends ModalProps {
  importCollection?: string[]; // 默认导入字样
  defaultCollection?: string[];
  defalutValue?: "material" | "local";
  allowType: string;
  handleOkBtnClick: (e: IMaterialDetail[]) => void;
  allowMetarial?: number[]; // 1视频 2图片 3音频 4文件夹
  disabledRecordStation?: boolean;
  maxCollectionLength?: number;
  filterCondition?: (item: IMaterialDetail) => boolean;
}

const toRaidoTypeGetMaterialType = (raidoType: string, type: number[]) => {
  if (raidoType === "素材库" || raidoType === "material") return type;
  return [4];
};

const MaterialFileModal: React.FC<IMaterialFileModalProps> = observer(
  (props) => {
    const {
      allowType,
      handleOkBtnClick,
      allowMetarial,
      defalutValue,
      importCollection,
      defaultCollection,
      disabledRecordStation = false,
      maxCollectionLength,
      filterCondition,
      ...res
    } = props;
    const {
      projectList,
      handleModifyNavList,
      handleToIdDeleteNavItem,
      toKeyWordGetNavItem,
      handleDeleteProjectItem,
    } = useNavProject();
    const [radioType, setRadioType] = useState(
      defalutValue === "material" ? "素材库" : "本地",
    );
    const [collection, setCollection] = useState<IMaterialDetail[]>([]);
    const [projectId, setProjectId] = useState<number>(null);
    const [folderList, setFolderList] = useState<any[]>([
      { name: "素材库", id: 0 },
    ]);
    const {
      searchData,
      pageParams: page,
      data,
      loading,
      handleRequest,
      handlePageChange,
    } = useRequest<IMaterialDetail, any>({
      request: getMaterialUploadData,
      initSearchData: {
        projectId: projectId,
        parentId: 0,
        type: toRaidoTypeGetMaterialType(radioType, allowMetarial),
      },
      page: {
        page: 1,
        page_size: 10,
      },
      manual: true,
    });
    const floderItemClick = debounce((item: IMaterialDetail) => {
      if (folderList.length === 0) {
        setFolderList([{ name: "素材库", id: 0 }, item]);
      } else {
        setFolderList([...folderList, item]);
      }
      handleRequest({
        projectId: searchData.projectId,
        parentId: item.id,
        type: toRaidoTypeGetMaterialType(radioType, allowMetarial),
      });
    }, 600);

    const handleBreamCrumbItemClick = (
      item: IMaterialDetail | { name: string; id: number },
    ) => {
      const index = folderList.findIndex((step) => step.id === item.id);
      if (index === folderList.length - 1) {
        return;
      }
      let clickId = "";
      if (index === 0) {
        setFolderList([{ name: "素材库" }]);
        clickId = 0 as any;
      } else {
        const newFolderList = folderList.slice(0, index + 1);
        setFolderList([...newFolderList]);
        clickId = newFolderList[newFolderList.length - 1]?.id;
      }
      handleRequest({
        parentId: clickId,
        projectId: searchData.projectId,
        type: toRaidoTypeGetMaterialType(radioType, allowMetarial),
      });
    };

    const handleProjectIdChange = (id: number) => {
      setProjectId(id as any);
      setFolderList([{ name: "素材库", id: 0 }]);
      handleRequest({
        projectId: id,
        parentId: 0,
        type: toRaidoTypeGetMaterialType(radioType, allowMetarial),
      });
    };

    const toCollectionGetMaterialInfo = () => {
      if (!collection || collection.length === 0) {
        return null;
      }
      return collection.map((item) => {
        return {
          ...item,
          type: item.type === "MOVIE" ? 1 : item.type,
        };
      });
    };

    const localCollection = (e: boolean, materialItem: IMaterialDetail) => {
      let newCollection = [...collection];
      if (e) {
        if (maxCollectionLength && collection.length === maxCollectionLength) {
          Message.error("以超过最大选择数，无法再次添加！");
          return;
        }
        newCollection = [...newCollection, materialItem];
      } else {
        const index = newCollection.findIndex(
          (item) => materialItem.id === item.id,
        );
        newCollection.splice(index, 1);
      }
      setCollection(newCollection);
    };

    useEffect(() => {
      if (projectList.length > 0) {
        setProjectId(projectList[0].id);
        handleRequest({
          projectId: projectList[0].id as any as number,
          parentId: 0,
          type: toRaidoTypeGetMaterialType(radioType, allowMetarial),
        });
      }
    }, [projectList.length]);

    const handleRadioTypeChange = (e) => {
      setFolderList([{ name: "素材库", id: 0 }]);
      setRadioType(e);
      if (projectId) {
        handleRequest({
          projectId: projectId,
          parentId: 0,
          type: toRaidoTypeGetMaterialType(
            e,
            e === "本地" ? [4] : allowMetarial,
          ),
        });
      }
    };

    const initCollection = async () => {
      const newCollection = await Promise.all(
        (defaultCollection || []).map(async (item) => {
          const resultItem = await getSimpleMedia(Number(item));
          return resultItem;
        }),
      );
      setCollection([...newCollection]);
    };

    useEffect(() => {
      initCollection();
    }, [defaultCollection]);

    useEffect(() => {
      if (defalutValue === "local") {
        handleRadioTypeChange("本地");
      }
    }, [defalutValue]);

    return (
      <Modal
        {...res}
        closable={false}
        unmountOnExit={true}
        onOk={() => {
          handleOkBtnClick(toCollectionGetMaterialInfo());
        }}
        className={`${res.className} vl-modal materail-file-modal`}
        style={{ ...res.style, width: 1060 }}>
        <div className="modal-header">
          <div className="moadl-title">
            <div>
              <img width={26} src={project_modal_icon} alt="" />
              <span>添加素材</span>
            </div>
            <div>
              <IconClose
                onClick={() => {
                  res.onCancel();
                }}
              />
            </div>
          </div>
        </div>
        <div className="materail-file-modal-content">
          <Radio.Group
            size="large"
            type="button"
            name="position"
            value={radioType}
            onChange={handleRadioTypeChange}
            options={["素材库", "本地"]}></Radio.Group>
          <div className="file-upload-modal-content">
            <ProjectNav
              projectId={projectId as any}
              style={{
                height: "604px",
                border: "1px solid #eee",
                borderRadius: 8,
              }}
              projectList={projectList}
              handleModifyNavList={handleModifyNavList}
              handleToIdDeleteNavItem={handleToIdDeleteNavItem}
              toKeyWordGetNavItem={toKeyWordGetNavItem}
              handleProjectIdChange={(id) => {
                handleProjectIdChange(id as any);
              }}
              handleProjectItemDelete={handleDeleteProjectItem}
              disabledRecordStation={disabledRecordStation}
            />
            <div className="material-file-modal-contaienr">
              <div className="opeator-bar">
                {/* <Button type="text">新建文件夹</Button> */}
              </div>
              {folderList?.length ? (
                <>
                  <SimpleBream
                    list={folderList}
                    handleBreamCrumbItemClick={(item) => {
                      handleBreamCrumbItemClick(item);
                    }}
                  />
                </>
              ) : (
                <div style={{ height: 22 }}></div>
              )}
              <div style={{ marginLeft: "-22px" }}>
                <BatchCollection
                  disabeldAll={true}
                  number={collection.length}
                  total={data?.length || 0}
                  handleCancel={() => {
                    setCollection([]);
                  }}
                />
              </div>
              {radioType === "素材库" ? (
                <div className="material-wrapper">
                  <Spin
                    style={{ width: "100%", height: "100%" }}
                    loading={loading === "loading"}>
                    <MaterialContainer
                      filterCondition={filterCondition}
                      maxCollectionLength={maxCollectionLength}
                      importCollection={importCollection}
                      collection={collection}
                      handleCollectionChange={setCollection}
                      data={data || []}
                      handleFolderClick={(e) => {
                        floderItemClick(e);
                      }}
                      allCollectionLength={0}
                      tip={""}
                    />
                    <div className="pagination-wrapper">
                      <Pagination
                        showTotal={(total) => `共 ${total}条`}
                        total={page.total}
                        pageSize={page.page_size}
                        current={page.page}
                        sizeCanChange
                        onChange={(page, pageSize) => {
                          handlePageChange({ page, page_size: pageSize });
                        }}
                      />
                    </div>
                  </Spin>
                </div>
              ) : (
                <div className="local">
                  <LocalUpload
                    projectId={projectId}
                    currentFolderId={folderList[folderList.length - 1]?.id || 0}
                    handleFolderItemClick={(e) => {
                      floderItemClick(e);
                    }}
                    filterCondition={filterCondition}
                    data={data}
                    collection={collection}
                    onChange={(state, item) => {
                      localCollection(state, item);
                    }}
                    allowType={allowType}
                    taskId={""}></LocalUpload>
                  <div className="pagination-wrapper">
                    <Pagination
                      showTotal={(total) => `共 ${total}条`}
                      total={page.total}
                      pageSize={page.page_size}
                      current={page.page}
                      showJumper
                      sizeCanChange
                      onChange={(page, pageSize) => {
                        handlePageChange({ page, page_size: pageSize });
                      }}
                    />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </Modal>
    );
  },
);

export default MaterialFileModal;
