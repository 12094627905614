import React, { useState } from "react";
import { bulb } from "@/assets/user-center";
import "./index.less";
import { Button, Input, Message } from "@arco-design/web-react";
import { addTeam } from "@/services/user/user";
import { setTokenToLocalStorage } from "@/utils/token";
import User from "@/stores/userStore";
interface ICreateContentProps {
  handleChange: () => void;
  setSteps: (opt: number) => void;
}
const CreateContent: React.FC<ICreateContentProps> = (props) => {
  const { handleChange, setSteps } = props;
  const [value, setValue] = useState("");
  const createTeamFun = async () => {
    const res = await addTeam({
      name: value,
    });
    if (res?.code == 0) {
      setTokenToLocalStorage(res.data.token || "");
      Message.success("创建成功");
      User.getTeamInfo();
      setSteps(2);
    } else {
      Message.error(res);
    }
  };
  return (
    <>
      <div className="create-team-box" style={{ margin: "0 35px" }}>
        <div className="team-moadl-msg" style={{ marginTop: 0 }}>
          <img src={bulb} width={20} alt="" />{" "}
          <h1>快来创建一个属于自己的团队吧</h1>
        </div>
        <div className="team-moadl-input">
          <Input
            style={{ width: 350 }}
            value={value}
            placeholder="请输入团队名称"
            onChange={(e) => {
              setValue(e);
            }}
            maxLength={20}
            showWordLimit
          />
        </div>
      </div>
      <div className="modal-footer">
        <Button
          onClick={() => {
            handleChange();
            setValue("");
          }}>
          取消
        </Button>
        <Button
          disabled={!value}
          type="primary"
          onClick={() => {
            createTeamFun();
          }}>
          下一步
        </Button>
      </div>
    </>
  );
};

export default CreateContent;
