import React, { useEffect, useRef, useState } from "react";
import "./index.less";
import { Button } from "@arco-design/web-react";
import { finish_success } from "@/assets/user-center";
import { useNavigate, useLocation } from "react-router-dom";
interface IAttestationContentProps {
  setSteps: (opt: number) => void;
  handleChange: () => void;
}
const FinishContent: React.FC<IAttestationContentProps> = (props) => {
  const { setSteps, handleChange } = props;
  const [remainingTime, setRemainingTime] = useState(3);
  const timerRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    // 设置定时器，每秒减少一次剩余时间
    timerRef.current = setInterval(() => {
      setRemainingTime((prevTime) => {
        if (prevTime > 1) {
          return prevTime - 1;
        } else {
          if (timerRef.current) {
            clearInterval(timerRef.current);
            setSteps(1);
            if (location.pathname != "/work-bench") {
              navigate("/work-bench");
            }
            handleChange();
            return 0;
          }
        }
      });
    }, 1000);

    return () => {
      if (timerRef.current) {
        clearInterval(timerRef.current);
      }
    };
  }, []);
  return (
    <>
      <div className="finish-root">
        <div>
          <img src={finish_success} width={26} alt="" />
          <p>提交成功</p>
        </div>
        <h2>请耐心等待工作人员审核</h2>
        <p>{remainingTime}s 后自动跳转至工作台....</p>
      </div>
      <div className="modal-footer">
        <Button
          //   disabled={!attestationType}
          type="primary"
          onClick={() => {
            handleChange();
            clearInterval(timerRef.current);
            timerRef.current = null;
            setSteps(1);
            if (location.pathname != "/work-bench") {
              navigate("/work-bench");
            }
          }}>
          完成
        </Button>
      </div>
    </>
  );
};

export default FinishContent;
