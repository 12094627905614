import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Message,
  Spin,
  Upload,
} from "@arco-design/web-react";

import { IconPlus } from "@arco-design/web-react/icon";
import { id_behind, id_border, id_front } from "@/assets/user-center";
import {
  getDocumentUploadToken,
  setFile_teamOverview,
} from "@/services/temeover";
import {
  getPersonal_info,
  getPersonalBackocr,
  getPersonalCardocr,
  getUserAvatarUrl,
} from "@/services/user/user";
import _ from "lodash";
import "./index.less";
const FormItem = Form.Item;
interface IPersonProps {
  setSteps: (opt: number) => void;
}
const Person: React.FC<IPersonProps> = (props) => {
  const { setSteps } = props;
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loadingAttestation, setLoadingAttestation] = useState<boolean>(false);
  const [form] = Form.useForm();
  const handleSubMit = async (values) => {
    setLoadingAttestation(true);
    const res = await getPersonal_info({
      ...values,
      front_image_url: values.front_image_url.subUrl,
      back_image_url: values.back_image_url.subUrl,
    });
    setLoadingAttestation(false);
    if (res.code == 0) {
      setSteps(3);
    } else {
      Message.error(res);
    }
  };
  return (
    <>
      <div className="person-upload-root">
        <div className="enterprise-form">
          <Spin loading={loadingForm}>
            <Form
              autoComplete="off"
              layout="vertical"
              form={form}
              onSubmit={handleSubMit}>
              <div style={{ display: "flex", alignItems: "center" }}>
                <FormItem
                  style={{ width: 178 }}
                  label="身份证"
                  field="front_image_url"
                  shouldUpdate
                  triggerPropName="fileList"
                  rules={[{ required: true, message: "身份证为必填项" }]}>
                  {() => {
                    return (
                      <Upload
                        multiple
                        showUploadList={false}
                        accept=".png, .jpg, .jpeg, .webp"
                        customRequest={async (file) => {
                          const { name, size } = file.file;
                          // 检查文件大小是否超过1MB
                          const maxSizeInBytes = 50 * 1024 * 1024; // 1MB
                          if (size > maxSizeInBytes) {
                            Message.error({
                              content: "文件大小超过50MB，请选择较小的文件。",
                              duration: 2000,
                            });
                            return;
                          }
                          try {
                            setLoadingForm(true);
                            const token = await getDocumentUploadToken(
                              name,
                              size,
                            );
                            const res: any = await setFile_teamOverview(
                              token,
                              file.file,
                            );
                            const avatar = await getUserAvatarUrl(
                              res.object_key,
                            );
                            const personal = await getPersonalCardocr({
                              front_image_key: res.object_key,
                            });
                            if (personal.code == 0) {
                              form.setFieldsValue({
                                name: personal.data.name,
                                front_image_url: {
                                  url: avatar.data.url,
                                  uid: 1,
                                  subUrl: res.object_key,
                                },
                                identity_card: personal.data.id_number,
                                gender: personal.data.gender == "男" ? 1 : 2,
                              });
                            }
                          } catch (error) {
                            Message.error({
                              content:
                                "身份证信息识别失败，请按页面顺序上传清晰的图片",
                              duration: 2000,
                            });
                            form.resetFields();
                          }
                          setLoadingForm(false);
                        }}>
                        <div>
                          <div
                            className="trigger"
                            style={{
                              backgroundImage: `url(${id_border})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }}>
                            <div className="trigger-hover">
                              <IconPlus />
                              <span>点击上传</span>
                            </div>
                            <img
                              style={{
                                width: 148,
                                height: 96,
                              }}
                              src={
                                form.getFieldValue("front_image_url")?.url ||
                                id_front
                              }
                              alt=""
                            />
                          </div>
                          <p className="trigger-text">点击上传人像面</p>
                        </div>
                      </Upload>
                    );
                  }}
                </FormItem>
                <div className="behind-box">
                  <FormItem
                    field="back_image_url"
                    label=" "
                    rules={[{ required: true, message: "身份证背面为必填项" }]}>
                    {() => {
                      return (
                        <Upload
                          multiple
                          showUploadList={false}
                          accept=".png, .jpg, .jpeg, .webp"
                          customRequest={async (file) => {
                            const { name, size } = file.file;
                            // 检查文件大小是否超过1MB
                            const maxSizeInBytes = 50 * 1024 * 1024;
                            if (size > maxSizeInBytes) {
                              Message.error({
                                content: "文件大小超过50MB，请选择较小的文件。",
                                duration: 2000,
                              });
                              return;
                            }
                            try {
                              setLoadingForm(true);
                              const token = await getDocumentUploadToken(
                                name,
                                size,
                              );
                              const res: any = await setFile_teamOverview(
                                token,
                                file.file,
                              );
                              const avatar = await getUserAvatarUrl(
                                res.object_key,
                              );
                              const personal = await getPersonalBackocr({
                                back_image_key: res.object_key,
                              });
                              if (personal.code == 0) {
                                form.setFieldsValue({
                                  back_image_url: {
                                    url: avatar.data.url,
                                    uid: 1,
                                    subUrl: res.object_key,
                                  },
                                  end_date: personal.data.end_date,
                                  start_date: personal.data.start_date,
                                });
                              }
                            } catch (error) {
                              Message.error({
                                content:
                                  "身份证信息识别失败，请按页面顺序上传清晰的图片",
                                duration: 2000,
                              });
                              form.resetFields();
                            }
                            setLoadingForm(false);
                          }}>
                          <div style={{ marginLeft: 35 }}>
                            <div
                              className="trigger"
                              style={{
                                backgroundImage: `url(${id_border})`,
                                backgroundRepeat: "no-repeat",
                                backgroundSize: "cover",
                              }}>
                              <div className="trigger-hover">
                                <IconPlus />
                                <span>点击上传</span>
                              </div>
                              <img
                                style={{
                                  width: 148,
                                  height: 96,
                                }}
                                src={
                                  form.getFieldValue("back_image_url")?.url ||
                                  id_behind
                                }
                                alt=""
                              />
                            </div>
                            <p className="trigger-text">点击上传国徽面</p>
                          </div>
                        </Upload>
                      );
                    }}
                  </FormItem>
                </div>
                <div className="upload-info">
                  <p>1、请使用原件扫描/拍照后上传；</p>
                  <p>2、支持png、jpg、JPEG格式，50MB以内；</p>
                </div>
              </div>

              <FormItem
                field="name"
                label="姓名"
                rules={[{ required: true, message: "姓名为必填项" }]}>
                <Input
                  style={{ fontSize: "16px" }}
                  className="form-contorinput"
                  autoComplete="username"
                  allowClear={true}
                  placeholder="请输入"
                />
              </FormItem>
              <FormItem hidden field="gender">
                <Input />
              </FormItem>
              <FormItem hidden field="start_date">
                <Input />
              </FormItem>
              <FormItem hidden field="end_date">
                <Input />
              </FormItem>
              <FormItem
                style={{ margin: 0 }}
                field="identity_card"
                label="身份证号"
                rules={[{ required: true, message: "身份证号为必填项" }]}>
                <Input
                  style={{ fontSize: "16px" }}
                  className="form-contorinput"
                  autoComplete="username"
                  allowClear={true}
                  placeholder="请输入"
                />
              </FormItem>
            </Form>
          </Spin>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          loading={loadingAttestation}
          type="primary"
          onClick={_.debounce(() => {
            form.submit();
          }, 500)}>
          下一步
        </Button>
      </div>
    </>
  );
};

export default Person;
