import { ProjectTypeEnum } from "@/pages/CreationSpace/components/ProjectCard/ProjectCard";
import empty from "@/assets/create-space/script-clear.png";
import FolderCard from "../FolderCard/FolderCard";
import CheckBoxHoc from "../CheckBoxHoc/CheckBoxHoc";
import MaterialCard from "../MaterialCard/MaterialCard";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import { CardTypeEnum } from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import "./MaterialContianer.less";
import { Message } from "@arco-design/web-react";
import _ from "lodash";
export const getCheckboxState = (arr: string[], name: string) => {
  return arr.indexOf(name) > -1;
};

interface IMaterialContaienrPorps {
  importCollection?: string[];
  collection: IMaterialDetail[];
  handleCollectionChange: (data: IMaterialDetail[]) => void;
  data: IMaterialDetail[];
  handleFolderClick: (fileInfo: any) => void;
  maxCollectionLength?: number;
  allCollectionLength: number;
  tip: string;
  filterCondition?: (item: IMaterialDetail) => boolean;
}

const MaterialContainer: React.FC<IMaterialContaienrPorps> = (props) => {
  const {
    collection,
    handleCollectionChange,
    data,
    allCollectionLength,
    handleFolderClick,
    importCollection,
    maxCollectionLength,
    filterCondition,
    tip,
  } = props;
  const handleChangeCollection = (item: IMaterialDetail, e: boolean) => {
    if (e) {
      if (maxCollectionLength === allCollectionLength) {
        return Message.error(tip);
      }
      const newCollection = [...collection];
      newCollection.push(item);
      handleCollectionChange(newCollection);
    } else {
      const index = collection.findIndex((step) => item.id === step.id);
      if (index > -1) {
        const newCollection = [...collection];
        newCollection.splice(index, 1);
        handleCollectionChange(newCollection);
      }
    }
  };

  return (
    <div className="material-folder-container">
      {data && data.length > 0 ? (
        <div className="upload-material-folder-contaienr">
          {(data || []).map((item, index) => {
            return (
              <div
                key={item.id}
                style={{ marginRight: 10, marginBottom: 7 }}
                onClick={_.debounce(() => {
                  if (item.type === (ProjectTypeEnum.FOLDER as any)) {
                    handleFolderClick(item);
                  }
                }, 500)}
              >
                {item.type === (ProjectTypeEnum.FOLDER as any) ? (
                  <FolderCard name={item.name} />
                ) : (
                  <>
                    <CheckBoxHoc
                      style={{ height: 112, borderRadius: 8 }}
                      checked={
                        collection.findIndex((step) => step.id === item.id) > -1
                      }
                      disabled={
                        filterCondition ? !filterCondition(item) : false
                      }
                      onCheckedChange={(e) => {
                        handleChangeCollection(item, e);
                      }}>
                      <MaterialCard
                        onClick={() => {
                          handleChangeCollection(
                            item,
                            !(
                              collection.findIndex(
                                (step) => step.id === item.id,
                              ) > -1
                            ),
                          );
                        }}
                        isImport={(importCollection || []).includes(item.id)}
                        type={item.type as CardTypeEnum}
                        img={item?.play_info?.cover_url}
                      />
                    </CheckBoxHoc>
                    <div className="file-upload-small-material-card-content">
                      <p>{item.name}</p>
                    </div>
                  </>
                )}
              </div>
            );
          })}
        </div>
      ) : (
        <div className="upload-modal-empty">
          <img src={empty} alt="" />
          <p>暂无数据</p>
        </div>
      )}
    </div>
  );
};

export default MaterialContainer;
