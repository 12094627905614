import React, { useState, useEffect } from "react";
import Modals from "@/components/UI/Modals";
import {
  Button,
  Form,
  Image,
  Input,
  Message,
  Select,
} from "@arco-design/web-react";
import { useNavigate } from "react-router-dom";
import {
  createScript,
  getProjectList,
} from "@/services/creativeScript/explosiveScript";
import { IconClose } from "@arco-design/web-react/icon";
import { inscript_icon } from "@/assets/creationscriptimage";
import iconImg from "@/assets/create-space/project-modal-icon.png";
import { usecChangeCreationStore } from "@/pages/CreationScript/useSetCreationStore";
import { setScriptCount } from "@/services/creativeScript/creationStores";
const Option = Select.Option;
interface IscriptData {
  storyboardName?: string;
  pictureDescription?: string;
  caption?: string;
  view?: string;
  model?: string;
  cameraMovement?: string;
  remark?: string;
  prop?: string;
  place?: string;
}
interface IStructuringModal {
  handleChangeModal: () => void;
  isShowModal: boolean;
  title?: string;
  scriptData?: IscriptData[];
  isCreate?: boolean;
  isNavigate?: boolean;
  modalTitle?: string;
  hot_id?: number;
}
const StructuringModal: React.FC<IStructuringModal> = (props) => {
  const {
    handleChangeModal,
    isShowModal,
    scriptData = null,
    title = "",
    isCreate = true,
    isNavigate = true,
    modalTitle = "创建脚本",
    hot_id = undefined,
  } = props;
  const navigate = useNavigate();
  const [projectOption, setProjectOption] = useState([]);
  const [projectName, setProjectName] = useState<string>("");
  const [form] = Form.useForm();
  const handleClick = (id) => {
    if (isNavigate) {
      navigate("/creation-script", {
        state: { isCreated: form.getFieldsValue(), id },
      });
    }
  };
  const getProjectListFun = async () => {
    const result = await getProjectList({
      keyword: "",
    });
    const res = result.data;
    if (res && res.items) {
      setProjectOption(res.items);
    }
  };
  const getScriptListFun = async (value) => {
    const res = await createScript({
      title: value.title,
      project_id: value.project_id,
      content: JSON.stringify(
        usecChangeCreationStore(
          scriptData ||
            new Array(3).fill(0).map((v, i) => ({
              storyboardName: `分镜名称${++i}`,
              pictureDescription: `画面描述${i}`,
              caption: `台词文案${i}`,
            })),
        ),
      ),
      scene_count: 1,
    });
    await setScriptCount({
      id: hot_id,
      type: 2,
    });
    if (res && res?.data?.id) {
      handleChangeModal();
      Message.success(`已保存至${projectName}项目的脚本库`);
      if (isCreate) {
        handleClick(res.data.id || 0);
      }
    } else {
      Message.warning(res);
    }
  };
  useEffect(() => {
    if (isShowModal) {
      getProjectListFun();
      if (title) {
        form.setFieldValue("title", title.slice(0, 19));
      }
    } else {
      form.resetFields();
    }
  }, [isShowModal]);

  return (
    <Modals
      width={386}
      title={modalTitle}
      cancelBtn={
        <Button
          onClick={() => {
            form.resetFields();
            handleChangeModal();
          }}>
          取消
        </Button>
      }
      subMiteBtn={
        <Button
          type="primary"
          onClick={() => {
            form.submit();
          }}>
          保存
        </Button>
      }
      titleIcon={<Image width={26} preview={false} src={iconImg} />}
      closeIcon={
        <IconClose
          onClick={() => {
            form.resetFields();
            handleChangeModal();
          }}
        />
      }
      visible={isShowModal}>
      <div className="modals-content">
        <Form
          form={form}
          layout="vertical"
          onSubmit={(value) => {
            getScriptListFun(value);
          }}>
          <Form.Item
            field={"title"}
            label="脚本名称"
            rules={[
              {
                required: true,
                message: "请填写脚本名称",
              },
            ]}>
            <Input
              maxLength={20}
              showWordLimit
              placeholder="请输入名称"
              autoComplete="off"
            />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
            field="project_id"
            label="保存位置"
            rules={[
              {
                required: true,
                message: "请选择保存位置",
              },
            ]}>
            <Select
              className="vlong-select-box"
              placeholder="请选择"
              allowClear
              onChange={(e) => {
                console.log(projectOption.find((v) => v.id == e)?.name || "");

                setProjectName(
                  projectOption.find((v) => v.id == e)?.name || "",
                );
              }}>
              {projectOption.map((option) => (
                <Option key={option.id} value={option.id}>
                  {option.name}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </div>
    </Modals>
  );
};

export default StructuringModal;
