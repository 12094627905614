import { ReactNode, useEffect, useRef, useState } from "react";
import { IconCaretRight } from "@arco-design/web-react/icon";
import "./VideoHoc.less";

export interface IVideoHocProps {
  url: string;
  children?: ReactNode;
  style: React.CSSProperties;
  disMuted?: boolean;
  controls?: boolean;
  leaveEvent?: boolean;
}

export function formatTime(milliseconds: number) {
  const totalSeconds = Math.floor(milliseconds / 1000);
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = totalSeconds % 60;
  const formattedMinutes = String(minutes).padStart(2, "0");
  const formattedSeconds = String(seconds).padStart(2, "0");
  return `${formattedMinutes}:${formattedSeconds}`;
}

const VideoHoc: React.FC<IVideoHocProps> = (props) => {
  const {
    url,
    children,
    style,
    disMuted,
    controls = false,
    leaveEvent = true,
  } = props;
  const [videoVisible, setVideoVisible] = useState(false);
  const videoHoc = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);

  // 函数：处理鼠标移出事件，隐藏视频并重置视频状态
  const handleVideoHocMouseOver = () => {
    setVideoVisible(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
      videoRef.current.src = "";
    }
  };

  // 添加 Intersection Observer 以监测组件是否在视口中
  useEffect(() => {
    const element = videoHoc.current;
    if (!element) return;

    // 回调函数，当可见性发生变化时调用
    const handleIntersection: IntersectionObserverCallback = (entries) => {
      entries.forEach((entry) => {
        if (!entry.isIntersecting && videoVisible) {
          handleVideoHocMouseOver();
        }
      });
    };

    // 创建 Intersection Observer 实例
    const observer = new IntersectionObserver(handleIntersection, {
      root: null, // 视口作为根元素
      threshold: 0.1, // 当组件至少有10%可见时触发
    });

    observer.observe(element);

    return () => {
      observer.unobserve(element);
      observer.disconnect();
    };
  }, [videoVisible]);

  // 处理鼠标离开事件，条件式添加监听器
  useEffect(() => {
    const handleMouseLeave = (event: MouseEvent) => {
      if (
        videoHoc.current &&
        !videoHoc.current.contains(event.relatedTarget as Node)
      ) {
        handleVideoHocMouseOver();
      }
    };
    if (leaveEvent && videoHoc.current) {
      videoHoc.current.addEventListener("mouseleave", handleMouseLeave);
    }

    return () => {
      if (videoHoc.current && leaveEvent) {
        videoHoc.current.removeEventListener("mouseleave", handleMouseLeave);
      }
    };
  }, [leaveEvent]);

  // 处理点击播放按钮
  const handlePlayClick = () => {
    setVideoVisible(true);
    setTimeout(() => {
      if (videoRef.current) {
        videoRef.current.src = url;
        videoRef.current.play().catch((error) => {
          console.error("视频播放失败:", error);
        });
      } else {
        console.log("没有video元素");
      }
    }, 0);
  };

  // 处理视频结束事件，隐藏视频并重置状态
  const handleVideoEnd = () => {
    setVideoVisible(false);
    if (videoRef.current) {
      videoRef.current.currentTime = 0;
      videoRef.current.src = "";
    }
  };

  return (
    <div className="_video-hoc-wrapper" style={style} ref={videoHoc}>
      {!videoVisible ? (
        <div className="_children-wrapper">{children}</div>
      ) : null}

      {!videoVisible && (
        <div className="_video-play-button" onClick={handlePlayClick}>
          <IconCaretRight />
        </div>
      )}
      {videoVisible && (
        <div className="_video-wrapper">
          <video
            crossOrigin="anonymous"
            ref={videoRef}
            controls={controls}
            muted={!disMuted}
            onEnded={handleVideoEnd}
            style={{
              width: "100%",
              height: "100%",
            }}></video>
        </div>
      )}
    </div>
  );
};

export default VideoHoc;
