/* eslint-disable no-useless-escape */
import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { Decoration, DecorationSet } from "prosemirror-view";
import { Fragment, Slice, Node as ProseMirrorNode } from "prosemirror-model";
import User from "@/stores/userStore";

interface ComposeState {
  text: string;
  from: number;
  to: number;
}

interface ICursorUserInfo {
  name: string;
  position: number;
  colorInfo: string;
  id: string;
  range: {
    from: number;
    to: number;
  };
}

interface ICursorPositionProps {
  RowId: number;
  ColId: number;
  userInfo: ICursorUserInfo[];
}

export interface CharacterCountOptions {
  limit: number | null | undefined;
  mode: "textSize" | "nodeSize";
  textLength: number;
  cursorPositionInfo: ICursorPositionProps | null;
  isFormat: boolean;
  isCharacterLimit: boolean;
  singleLine: number;
}

export interface CharacterCountStorage {
  characters: (options?: {
    node?: ProseMirrorNode;
    mode?: "textSize" | "nodeSize";
  }) => number;
  words: (options?: { node?: ProseMirrorNode }) => number;
  getColorInfo: () => string;
  setCursorPositions: (positions: ICursorUserInfo[]) => void;
}
const PUNCTUATION_REGEX = /[,，.。;；:：?？!！………—、-]/g;
export const CharacterCount = Extension.create<
  CharacterCountOptions,
  CharacterCountStorage
>({
  name: "characterCount",

  addOptions() {
    return {
      limit: null,
      mode: "textSize",
      textLength: 0,
      cursorPositionInfo: null,
      isFormat: false,
      isCharacterLimit: false,
      singleLine: 0,
    };
  },

  addStorage() {
    return {
      characters: () => 0,
      words: () => 0,
      getColorInfo() {
        const hex = Math.floor(Math.random() * 0xffffff).toString(16);
        return "#" + ("000000" + hex).slice(-6);
      },
      setCursorPositions: () => {
        // 初始化为空函数，实际实现将在 onCreate 中定义
      },
    };
  },

  onCreate() {
    const singleLine = this.options.singleLine;
    const limit = this.options.limit || 15;
    const isFormat = this.options.isFormat;
    let composing: any = false;
    let composeState: ComposeState;
    const pluginKey = new PluginKey("characterCount");
    const isCharacterLimit = this.options.isCharacterLimit;
    // 定义 setCursorPositions 方法
    this.storage.setCursorPositions = (positions: ICursorUserInfo[]) => {
      const { editor } = this;
      if (!editor) {
        console.warn("Editor instance is not available.");
        return;
      }
      const state = editor.state;
      const transaction = state.tr.setMeta(pluginKey, {
        cursorPositions: positions,
      });
      editor.view.dispatch(transaction);
    };

    // 创建装饰插件
    const decorationPlugin = new Plugin({
      key: pluginKey,
      state: {
        init: (config, state) => ({
          cursorPositions: this.options.cursorPositionInfo?.userInfo || [],
        }),
        apply: (tr, prev) => {
          const meta = tr.getMeta(pluginKey);
          if (meta && meta.cursorPositions) {
            return {
              cursorPositions: meta.cursorPositions,
            };
          }
          return prev;
        },
      },
      props: {
        decorations: (state) => {
          const pluginState = pluginKey.getState(state);
          const decorations: Decoration[] = [];
          const extractGradientColors = (gradientStr) => {
            // 使用正则表达式匹配 hex 颜色或 rgb/rgba 颜色
            const colorRegex = /#([0-9A-Fa-f]{3,6})\b|rgba?\([^\)]+\)/g;
            const colors = [];
            let match;
            // 循环匹配所有颜色
            while ((match = colorRegex.exec(gradientStr)) !== null) {
              // match[0] 是完整匹配的颜色字符串
              colors.push(match[0]);
            }

            return colors;
          };
          pluginState.cursorPositions.forEach((position) => {
            decorations.push(
              Decoration.widget(position.position, () => {
                const cursor = document.createElement("span");
                cursor.classList.add("collaboration-cursor__caret");
                cursor.setAttribute(
                  "style",
                  `border-color:${extractGradientColors(position.colorInfo).pop()};display:${position.id != User?.userInfo?.uuid ? "inline" : "none"};`,
                );
                const label = document.createElement("div");
                label.classList.add("collaboration-cursor__label");
                label.setAttribute(
                  "style",
                  `background:${position.colorInfo}; color: white;display: ${position.id != User?.userInfo?.uuid ? "block" : "none"};`,
                );

                label.textContent = position.name;

                cursor.appendChild(label);
                return cursor;
              }),
              Decoration.inline(position.range.from, position.range.to, {
                style: `background-color: ${position.id != User?.userInfo?.uuid ? extractGradientColors(position.colorInfo).shift() : "transparent"};color: ${position.id != User?.userInfo?.uuid ? "white" : "inherit"}`,
              }),
            );
          });

          return DecorationSet.create(state.doc, decorations);
        },
        handleDOMEvents: {
          paste(view, event) {
            event.preventDefault(); // 阻止默认粘贴行为
            const { state, dispatch } = view;
            const { from, to, $from } = state.selection;
            const currentNode = $from.node($from.depth - 1);
            const isInBulletList =
              currentNode && currentNode.type.name === "listItem";
            let text = event.clipboardData?.getData("text/plain") || "";

            const currentTextLength = state.doc.textContent.length;
            const selectionLength = to - from;
            const allowed = limit - (currentTextLength - selectionLength);

            if (isCharacterLimit) {
              if (allowed <= 0) {
                // 如果不允许插入任何字符，直接返回

                return true;
              }

              // 如果粘贴的文本超过允许的字符数，进行截断
              if (text.length > allowed) {
                text = text.slice(0, allowed);
              }

              if (!text) {
                // 如果剩余的文本为空，直接返回
                return true;
              }
            }
            if (singleLine > 0) {
              const currentDoc = state.doc;
              // 使用 ResolvedPos 获取更准确的位置
              const resolvedPos = currentDoc.resolve(from);
              const currentNode = currentDoc.nodeAt(resolvedPos.pos - 1);
              if ((currentNode.textContent + text).length > singleLine) {
                const over =
                  (currentNode.textContent + text).length - singleLine;
                if (text.length < over) {
                  return true;
                }
                text = text.slice(0, text.length - over);
              }
            }
            let slice: Slice | null = null;
            if (isInBulletList && isFormat) {
              // 在无序列表中处理粘贴内容，将标点符号替换为新列表项
              const parts = text
                .split(PUNCTUATION_REGEX)
                .map((part) => part.trim())
                .filter((part) => part.length > 0);

              if (parts.length > 1) {
                const { schema } = state;
                const listItemType = schema.nodes.listItem;
                const paragraphType = schema.nodes.paragraph;

                const listItems: ProseMirrorNode[] = parts.map((part) =>
                  listItemType.create(
                    {},
                    paragraphType.create({}, schema.text(part)),
                  ),
                );

                // 创建新的文档切片
                slice = new Slice(Fragment.fromArray(listItems), 0, 0);
              }
            }

            if (!slice && text) {
              // 如果不是在无序列表中，或者无法进行分割，则直接插入文本
              const { schema } = state;
              const nodes = schema.text(text);
              slice = new Slice(Fragment.from(nodes), 0, 0);
            }

            if (slice) {
              // 创建一个事务，将新内容插入文档
              const transaction = state.tr.replaceRange(from, to, slice);
              dispatch(transaction);
            }

            return true;
          },
          compositionstart(view) {
            composing = true;
            return true;
          },
          compositionend(view) {
            composing = false;
            const currentDoc = view.state.doc;
            if (currentDoc.textContent.length < limit) {
              return false;
            }
            // 创建事务，删除超出部分的内容
            const over = currentDoc.textContent.length - limit;
            const tr = view.state.tr.delete(
              composeState.to - over,
              composeState.to,
            );
            // 应用事务并更新视图
            view.dispatch(tr);
            return true;
          },
        },

        handleTextInput: (view, from, to, text) => {
          if (composing) {
            composeState = { text: text, from: from, to: from + text.length };
            return false;
          }
          // 获取当前文档的内容
          const currentDoc = view.state.doc;

          // 计算新插入的文本长度
          const newContentLength =
            currentDoc.textContent.length + text.length - (to - from);

          // 假设我们有一个字符数限制，比如 100
          if (newContentLength > limit) {
            console.log("====================================");
            console.log(123123);
            console.log("====================================");
            return true;
          }

          // 如果没有超过限制，执行插入
          return false;
        },
      },
    });

    // 将插件添加到编辑器
    if (this.editor) {
      this.editor.view.updateState(
        this.editor.view.state.reconfigure({
          plugins: this.editor.view.state.plugins.concat([decorationPlugin]),
        }),
      );
    } else {
      console.warn(
        "Editor instance is not available when adding decorationPlugin.",
      );
    }
  },

  onBeforeCreate() {
    // 初始化字符和词数统计方法
    this.storage.characters = (options) => {
      const node = options?.node || this.editor?.state.doc;
      if (!node) return 0;

      const mode = options?.mode || this.options.mode;

      if (mode === "textSize") {
        const text = node.textBetween(0, node.content.size, "");
        return text.length;
      }

      return node.nodeSize;
    };

    this.storage.words = (options) => {
      const node = options?.node || this.editor?.state.doc;
      if (!node) return 0;

      const text = node.textBetween(0, node.content.size, " ", " ");
      const words = text.split(" ").filter((word) => word !== "");
      return words.length;
    };
  },

  addProseMirrorPlugins() {
    // 此方法可以留空，因为我们已经在 onCreate 中添加了插件
    return [];
  },
});
