import React, { useState } from "react";
import {
  Button,
  Form,
  Input,
  Message,
  Spin,
  Upload,
} from "@arco-design/web-react";
import "./index.less";
import { IconPlus } from "@arco-design/web-react/icon";
import {
  getDocumentUploadToken,
  setFile_teamOverview,
} from "@/services/temeover";
import {
  getCompanyInfo,
  getCompanyLicense,
  getUserAvatar,
  getUserAvatarUrl,
} from "@/services/user/user";
import _ from "lodash";

const FormItem = Form.Item;
interface IEnterpriseProps {
  setSteps: (opt: number) => void;
}
const Enterprise: React.FC<IEnterpriseProps> = (props) => {
  const { setSteps } = props;
  const [form] = Form.useForm();
  const [loadingForm, setLoadingForm] = useState<boolean>(false);
  const [loadingAttestation, setLoadingAttestation] = useState<boolean>(false);
  const handleSubMit = async (values) => {
    setLoadingAttestation(true);
    const res = await getCompanyInfo({
      ...values,
      license: values.license.subUrl,
    });
    setLoadingAttestation(false);
    if (res.code == 0) {
      setSteps(3);
    } else {
      Message.error(res);
    }
  };
  return (
    <>
      <div className="enterprise-upload-root">
        <div className="upload-box"></div>
        <div className="enterprise-form">
          <Spin loading={loadingForm}>
            <Form
              autoComplete="off"
              layout="vertical"
              form={form}
              onSubmit={handleSubMit}>
              <div className="upload-box">
                <FormItem
                  shouldUpdate
                  style={{ width: 234 }}
                  field="license"
                  triggerPropName="fileList"
                  label="营业执照"
                  rules={[{ required: true, message: "企业名称为必填项" }]}>
                  {() => {
                    return (
                      <Upload
                        multiple
                        name="files"
                        showUploadList={false}
                        // accept=".png, .jpg, .jpeg, .gif, .webp"
                        accept=".png, .jpg"
                        customRequest={async (file) => {
                          const { name, size } = file.file;
                          // 检查文件大小是否超过1MB
                          const maxSizeInBytes = 3 * 1024 * 1024; // 1MB
                          if (size > maxSizeInBytes) {
                            Message.error({
                              content: "文件大小超过3MB，请选择较小的文件。",
                              duration: 2000,
                            });
                            return;
                          }
                          try {
                            setLoadingForm(true);
                            const token = await getDocumentUploadToken(
                              name,
                              size,
                            );
                            const res: any = await setFile_teamOverview(
                              token,
                              file.file,
                            );
                            const avatar = await getUserAvatarUrl(
                              res.object_key,
                            );
                            form.setFieldValue("license", {
                              url: avatar.data.url,
                              uid: 1,
                              subUrl: res.object_key,
                            });
                            const license = await getCompanyLicense({
                              license_key: res.object_key || "",
                            });
                            if (license.code == 0) {
                              form.setFieldsValue({
                                company_name: license.data.name,
                                credit_code: license.data.register_number,
                                legal_rep: license.data.legal_person,
                              });
                            }
                          } catch (error) {
                            Message.error({
                              content: "上传失败，请重试。",
                              duration: 2000,
                            });
                          }
                          setLoadingForm(false);
                        }}>
                        <div className="trigger">
                          {form.getFieldValue("license") ? (
                            <img
                              width="100%"
                              height="100%"
                              src={form.getFieldValue("license").url}
                              alt=""
                            />
                          ) : (
                            <>
                              <IconPlus />
                              <span>点击上传</span>
                            </>
                          )}
                        </div>
                      </Upload>
                    );
                  }}
                </FormItem>
                <div className="upload-info">
                  <p>1、营业执照需要通过年检；</p>
                  <p>2、请使用原件/加盖公章的复印件，扫描/拍照后上传；</p>
                  <p>3、支持png、jpg格式，3mb以内</p>
                </div>
              </div>
              <div style={{ display: "flex" }}>
                <FormItem
                  field="company_name"
                  label="企业名称"
                  rules={[{ required: true, message: "企业名称为必填项" }]}>
                  <Input
                    style={{ fontSize: "16px" }}
                    className="form-contorinput"
                    autoComplete="username"
                    allowClear={true}
                    placeholder="请输入"
                  />
                </FormItem>

                <FormItem
                  style={{
                    marginLeft: 14,
                  }}
                  field="legal_rep"
                  label="法定代表人"
                  rules={[{ required: true, message: "法定代表人为必填项" }]}>
                  <Input
                    style={{ fontSize: "16px" }}
                    className="form-contorinput"
                    autoComplete="username"
                    allowClear={true}
                    placeholder="请输入"
                  />
                </FormItem>
              </div>

              <FormItem
                style={{ margin: 0 }}
                field="credit_code"
                label="统一社会信用代码"
                rules={[{ required: true, message: "社会信用代码为必填项" }]}>
                <Input
                  style={{ fontSize: "16px" }}
                  className="form-contorinput"
                  autoComplete="username"
                  allowClear={true}
                  placeholder="请输入"
                />
              </FormItem>
            </Form>
          </Spin>
        </div>
      </div>
      <div className="modal-footer">
        <Button
          loading={loadingAttestation}
          type="primary"
          onClick={_.debounce(() => {
            form.submit();
          }, 500)}>
          下一步
        </Button>
      </div>
    </>
  );
};

export default Enterprise;
