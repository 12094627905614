/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Form,
  Image,
  Input,
  Message,
  Modal,
} from "@arco-design/web-react";
import User from "@/stores/userStore";
import "./index.less";
import {
  diamond,
  enterprise_environment,
  exclusive_background,
  exclusive_check,
  exclusive_text,
  enterprise_enjoy,
  datum,
  prd,
} from "@/assets/user-center";
import { IconClose, IconDelete } from "@arco-design/web-react/icon";
import { from } from "rxjs";
import { getUserContact } from "@/services/user/user";
import { useNavigate } from "react-router-dom";
import _ from "lodash";
const FormItem = Form.Item;
interface ICreateTeamProps {
  visible: boolean;
  handleChangeMoadl: () => void;
}
const ExclusiveModal: React.FC<ICreateTeamProps> = ({
  visible,
  handleChangeMoadl,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const [checked, setChecked] = useState<boolean>(false);
  const validatePassCheck = (value, callback) => {
    if (value === "") {
      callback("请输入手机号");
    } else {
      if (!/^1[3-9]\d{9}$/.test(value)) {
        callback("请输入正确手机号格式");
      } else {
        callback();
      }
    }
  };

  return (
    <Modal
      onCancel={handleChangeMoadl}
      style={{ width: 692 }}
      title={null}
      focusLock={false}
      visible={visible}
      footer={null}>
      <div className="exclusive-moadl-title">
        <img src={enterprise_enjoy} height={20} alt="" />
        <p>联系专属顾问，探索正式套餐更多精彩内容</p>
      </div>
      <div className="exclusive-root">
        <div>
          <div className="exclusive-header">
            <img src={prd} width={76} alt="" />
            <p></p>
          </div>
          <div className="exclusive-box">
            <div className="box-left">
              <img src={exclusive_check} width={18} alt="" />
            </div>
            <div className="box-right">
              <p>多样化创作工具</p>
              <p>提供更丰富的选择，助力创意实现</p>
            </div>
          </div>
          <div className="exclusive-box">
            <div className="box-left">
              <img src={exclusive_check} width={18} alt="" />
            </div>
            <div className="box-right">
              <p>实时热门素材更新</p>
              <p>紧跟当下热点，为创作注入新鲜活力</p>
            </div>
          </div>
          <div className="exclusive-box">
            <div className="box-left">
              <img src={exclusive_check} width={18} alt="" />
            </div>
            <div className="box-right">
              <p>全面满足企业需求</p>
              <p>定制化需求，提供一站式解决方案</p>
            </div>
          </div>
        </div>
        <div className="meal-form">
          <div className="exclusive-header">
            <img src={datum} width={76} alt="" />
            <p></p>
          </div>
          <Form
            autoComplete="off"
            form={form}
            onSubmit={_.debounce(async (values) => {
              const res = await getUserContact(values);
              if (!res.code) {
                Message.success("信息已提交，稍等片刻，会有专属客服飞奔向您!");
                User.setIsExpired();
                handleChangeMoadl();
              } else {
                Message.error(res);
              }
            }, 500)}>
            <FormItem
              label="联系人"
              field="contact_name"
              rules={[{ required: true, message: "联系人不能为空" }]}>
              <Input
                placeholder="请输入姓名"
                style={{ width: 222, marginLeft: 10 }}
              />
            </FormItem>
            <FormItem
              label="手机号"
              field="phone_number"
              rules={[
                {
                  validator: validatePassCheck,
                  required: true,
                },
              ]}>
              <Input
                placeholder="请输入手机号"
                style={{ width: 222, marginLeft: 10 }}
              />
            </FormItem>
            <FormItem
              label="企业名称"
              field="company_name"
              style={{ marginBottom: 0 }}>
              <Input
                placeholder="请输入企业名称"
                style={{ width: 222, marginLeft: 10 }}
              />
            </FormItem>
          </Form>
        </div>
      </div>
      <div className="exfooter-root">
        <Button
          onClick={() => form.submit()}
          icon={
            <img
              src={diamond}
              width={16}
              style={{ position: "relative", top: 3, right: 5 }}
            />
          }
          type="primary"
          className="get-btn">
          联系专属顾问
        </Button>
      </div>
    </Modal>
  );
};

export default ExclusiveModal;
