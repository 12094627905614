import {
  CardTypeEnum,
  toTypeGetTag,
} from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";
import "./ShowUploadNode.less";
import musicUpload from "@/assets/create-space/upload-music.png";
import { IconClose } from "@arco-design/web-react/icon";
export interface IShowUploadNodeProps {
  id: string;
  cover: string;
  type: CardTypeEnum;
  time?: string;
  name?: string;
  handleCloseBtnClick?: () => void;
  onClick?: () => void;
}

const ShowUploadNode: React.FC<IShowUploadNodeProps> = (props) => {
  const { cover, type, time, name, onClick, handleCloseBtnClick } = props;
  return (
    <div
      className="show-up-load-success"
      onClick={() => {
        if (onClick) {
          onClick();
        }
      }}>
      {handleCloseBtnClick ? (
        <div
          className="icon-close-btn"
          onClick={(e) => {
            e.stopPropagation();
            handleCloseBtnClick();
          }}>
          <IconClose />
        </div>
      ) : (
        <></>
      )}

      <div className="cover-img">
        {type === CardTypeEnum.MUSIC ? (
          <img src={musicUpload} style={{ objectFit: "contain" }} alt="" />
        ) : (
          <img src={cover} alt="" />
        )}

        <div className="type-tag-wrapper">{toTypeGetTag(type)}</div>
        {time ? <div className="time-wrapper">{time}</div> : <></>}
      </div>
      {name ? <p>{name}</p> : <></>}
    </div>
  );
};

export default ShowUploadNode;
