import React, { useState, useEffect, ReactNode } from "react";
interface IHighlightSearchProps {
  children: ReactNode;
  // 要高亮的搜索词
  searchTerm?: string;
}

// HighlightSearch组件 用于高亮显示文本中的搜索词
const HighlightSearch: React.FC<IHighlightSearchProps> = ({
  children,
  searchTerm,
}) => {
  const [content, setContent] = useState<ReactNode>(null);
  function escapeRegExp(string: string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
  }

  // 定义一个递归函数来遍历并替换文本中的搜索词
  const traverseAndReplace = (node: ReactNode): ReactNode => {
    // 如果节点是字符串或数字 则进行替换
    if (typeof node === "string" || typeof node === "number") {
      const parts = node
        .toString()
        .split(new RegExp(`(${escapeRegExp(searchTerm)})`, "gi"));

      // 将文本分割成多个部分
      return parts.map((part, i) =>
        part.toLowerCase() === searchTerm.toLowerCase() ? (
          <span style={{ color: "#165DFF" }} key={i}>
            {part}
          </span>
        ) : (
          <span key={i}>{part}</span>
        ),
      );
    } else if (React.isValidElement(node)) {
      // 先检查它是否有children属性
      if (node.props.children) {
        // 克隆元素并递归处理其子元素
        const clonedElement = React.cloneElement(
          node,
          {},
          React.Children.toArray(node.props.children).map(traverseAndReplace),
        );
        return clonedElement;
      } else {
        // 如果没有children属性 说明这是一个自闭合标签 如img 直接返回
        return node;
      }
    }

    // 如果节点是数组 则递归处理数组中的每个元素
    if (Array.isArray(node)) {
      return node.map(traverseAndReplace);
    }
    return node;
  };

  useEffect(() => {
    // 如果没有搜索词 则直接显示原始内容
    if (!searchTerm) {
      setContent(children);
      return;
    }
    // 设置内容状态
    setContent(traverseAndReplace(children));
  }, [children, searchTerm]);

  return <>{content}</>;
};

export default HighlightSearch;
