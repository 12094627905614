/* eslint-disable react/prop-types */
import { CSSProperties, useEffect, useRef, useState } from "react";
import { Input, Image, Button, Message } from "@arco-design/web-react";
import {
  abbreviation_w_icon,
  expand_w_icon,
  rewrite_icon,
  plane_icon,
} from "@/assets/creationscriptimage";
import "./index.less";
type AIBtnType = "改写" | "扩写" | "缩写" | "帮写";
interface ISmartSearch {
  /**
   * placeholder 默认提示词
   */
  placeholder: string;
  /**
   * disRewrite 改写按钮是否可用 默认不可用
   */
  disRewrite?: boolean;
  /**
   * disExpand 扩写按钮是否可用 默认不可用
   */
  disExpand?: boolean;
  /**
   * disAbbreviation 缩写按钮是否可用 默认不可用
   */
  disAbbreviation?: boolean;
  /**
   * handelClickBtn "改写" | "扩写" | "缩写" | "帮写" 按钮点击事件
   */
  handelClickBtn: (value: string, type: AIBtnType) => void;
  /**
   * isEnd 外部执行状态 打印是否结束
   */
  isEnd?: boolean;
  /**
   * editValue 编辑器内容
   */
  editValue?: string;
  /**
   * handleKeyDown 键盘事件
   */
  handleKeyDown?: (key: KeyboardEvent) => void;
  /**
   * styles 根元素样式
   */
  styles?: CSSProperties;
  /**
   * 是否阻止冒泡 true阻止，false不阻止
   */
  disStopPropagation?: boolean;
}

const SmartSearch: React.FC<ISmartSearch> = ({
  disRewrite = false,
  disExpand = false,
  disAbbreviation = false,
  editValue,
  handelClickBtn,
  placeholder,
  isEnd,
  handleKeyDown,
  styles,
  disStopPropagation,
}) => {
  const [isFocus, setIsFocus] = useState(false);
  const [value, setValue] = useState("");
  const searchRef = useRef<any>();
  const toolBtn = [
    { icon: rewrite_icon, text: "改写", status: disRewrite },
    { icon: expand_w_icon, text: "扩写", status: disExpand },
    {
      icon: abbreviation_w_icon,
      text: "缩写",
      status: disAbbreviation,
    },
  ];

  const handleOnFocus = () => {
    setIsFocus(true);
  };

  const handleOnBlur = () => {
    setIsFocus(false);
  };
  useEffect(() => {
    const handleKeyDownFun = (event: KeyboardEvent) => {
      if (handleKeyDown) {
        handleKeyDown(event);
      }
    };
    window.addEventListener("keydown", handleKeyDownFun);
    return () => {
      window.removeEventListener("keydown", handleKeyDownFun);
    };
  }, []);
  return (
    <div
      className="smart-search-root"
      style={styles}
      onClick={(e) => {
        if (disStopPropagation) {
          e.stopPropagation();
        }
      }}>
      <div className="smart-inpout-box" style={{ width: "100%" }}>
        <Input
          ref={searchRef}
          value={value}
          onChange={setValue}
          onFocus={handleOnFocus}
          onBlur={handleOnBlur}
          onPressEnter={() => {
            if (isEnd) {
              if (value) {
                handelClickBtn(value, "帮写");
                setValue("");
              } else {
                Message.error("不能为空!");
              }
            } else {
              Message.warning("正在生成中, 请稍后...");
            }
          }}
          suffix={
            <Image
              src={plane_icon}
              style={{ marginRight: 6, cursor: "pointer" }}
              width={30}
              preview={false}
              onClick={() => {
                if (isEnd) {
                  if (value) {
                    handelClickBtn(value, "帮写");
                    setValue("");
                  } else {
                    Message.error("不能为空!");
                  }
                } else {
                  Message.warning("正在生成中, 请稍后...");
                }
              }}
            />
          }
          placeholder={placeholder}
        />
      </div>
      <div
        className={`smart-tool-box${!isFocus ? "-h" : ""}`}
        style={{
          width: isFocus ? "0px" : "274px",
          transform: `translateX(${isFocus ? 280 : 0}px)`,
        }}>
        {toolBtn.map((item, index) => (
          <Button
            disabled={item.status == null}
            key={index}
            className="smart-tool-btn"
            style={{
              marginLeft: index == 0 ? 13 : 0,
              background: item.status
                ? "linear-gradient(42deg,rgba(119, 0, 255, 0.35) 0%,rgba(190, 123, 255, 0.35) 100%)"
                : "transparent",
            }}
            icon={<Image src={item.icon} width={18} preview={false} />}
            onClick={() => {
              if (isEnd) {
                if (item.status == null) {
                  return;
                }
                if (editValue && editValue.trim()) {
                  handelClickBtn(editValue, item.text as AIBtnType);
                  setValue("");
                } else {
                  Message.warning("选词不能为空!");
                }
              } else {
                Message.warning("正在生成中, 请稍后...");
              }
            }}>
            {item.text}
          </Button>
        ))}
      </div>
    </div>
  );
};

export default SmartSearch;
