// LineLimit.ts
import { Extension } from "@tiptap/core";
import { Plugin, PluginKey } from "prosemirror-state";
import { Fragment, Slice } from "prosemirror-model";
interface LineLimitOptions {
  singleLine: number; // 每行字符限制，0 表示不限制
}
interface ComposeState {
  text: string;
  from: number;
  to: number;
}

const LineLimitPluginKey = new PluginKey("lineLimit");

const LineLimit = Extension.create<LineLimitOptions>({
  name: "lineLimit",

  addOptions() {
    return {
      singleLine: 0, // 默认不限制
    };
  },

  addProseMirrorPlugins() {
    const { singleLine } = this.options;
    let composing = false;
    let composeState: ComposeState | null = null;

    if (singleLine <= 0) {
      return [];
    }

    return [
      new Plugin({
        key: LineLimitPluginKey,

        props: {
          handleDOMEvents: {
            compositionstart(view) {
              composing = true;
              return false; // 返回 false 以允许其他事件处理继续
            },
            compositionend(view) {
              composing = false;
              const { state } = view;
              const { from } = state.selection;
              const currentDoc = state.doc;
              // 使用 ResolvedPos 获取更准确的位置
              const resolvedPos = currentDoc.resolve(from);
              const currentNode = currentDoc.nodeAt(resolvedPos.pos - 1);
              if (currentNode.textContent.length < singleLine) {
                return false;
              }
              // 创建事务，删除超出部分的内容
              const over = currentNode.textContent.length - singleLine;
              const tr = view.state.tr.delete(
                composeState.to - over,
                composeState.to,
              );
              view.dispatch(tr);
              return true;
            },
          },

          // 阻止输入超过限制的字符
          handleTextInput: (view, from, to, text) => {
            if (composing) {
              composeState = { text: text, from: from, to: from + text.length };
              return false;
            }
            if (singleLine <= 0) {
              return false;
            }
            const { state, dispatch } = view;
            // 确保from在文档范围内
            if (from < 0 || from > state.doc.content.size) {
              return false;
            }

            // 使用 nodeAt 获取当前节点
            let currentNode = state.doc.nodeAt(from);
            if (!currentNode) {
              // 尝试获取前一个节点
              if (from > 0) {
                currentNode = state.doc.nodeAt(from - 1);
              }
              if (!currentNode) {
                return false;
              }
            }

            const currentLineText = currentNode.textContent || "";
            const selectionText = state.doc.textBetween(from, to, "\n");
            const newLength =
              currentLineText.length - selectionText.length + text.length;
            if (newLength > singleLine) {
              const allowedLength =
                singleLine - (currentLineText.length - selectionText.length);
              if (allowedLength > 0) {
                const allowedText = text.slice(0, allowedLength);
                dispatch(state.tr.insertText(allowedText, from, to));
              }
              return true; // 阻止原始输入
            }

            return false;
          },

          // 阻止粘贴超过限制的字符
        },

        // 可选：在事务后检查并修正文档中超出限制的部分
        // 可以添加 `apply` 方法来持续监控文档
      }),
    ];
  },
});

export default LineLimit;
