import React from "react";
import { ReactTyped, Typed } from "react-typed";
import { useEffect, useState } from "react";
import { Image, Popover, Tooltip } from "@arco-design/web-react";
import {
  change_icon,
  copy_icon,
  splice_icon,
  copy_b_icon,
  splice_b_icon,
  change_b_icon,
} from "@/assets/creationscriptimage";
import { IDocumentText } from "@/data/IntelligentProps";
import "./index.less";

const DocumentText: React.FC<IDocumentText> = ({
  btnStatus,
  renderClick,
  renderProps,
  data,
  underway,
}) => {
  const toolBtn = [
    {
      text: "change",
      icon: change_icon,
      activeIcon: change_b_icon,
      status: false,
      disabled: btnStatus == "change",
      msg: "替换",
    },
    {
      text: "copy",
      icon: copy_icon,
      activeIcon: copy_b_icon,
      status: false,
      disabled: btnStatus == "copy",
      msg: "复制",
    },
    {
      text: "splice",
      icon: splice_icon,
      activeIcon: splice_b_icon,
      status: false,
      disabled: btnStatus == "splice",
      msg: "添加",
    },
  ];
  const [btnIndex, setBtnIndex] = useState([-1, -1]);
  const [currentTextIndex, setCurrentTextIndex] = useState(0); // 当前正在显示的文本索引
  const [typedRefs, setTypedRefs] = useState<(Typed | undefined)[]>([]); // 存储所有Typed实例的引用
  const [isEnd, setIsEnd] = useState(0);
  // 启动所有 Typed 实例
  const handleStartAll = () => {
    // Object.keys(typedRefs.current).forEach((key) => {
    //   typedRefs.current[key].start();
    // });
    const currentIndex = currentTextIndex;
    if (currentIndex < data.length) {
      typedRefs[currentIndex]?.start(); // 继续打印当前文本
    }
  };

  const handleResetAll = () => {
    setCurrentTextIndex(0);
  };

  // 停止所有 Typed 实例
  const handleStopAll = () => {
    Object.keys(typedRefs).forEach((key) => {
      typedRefs[key].stop();
    });
    // const currentIndex = currentTextIndex;
    // if (currentIndex < data.length) {
    //   typedRefs[currentIndex]?.stop(); // 暂停当前文本的打印
    // }
  };
  const onComplete = (index: number) => {
    if (index < data.length - 1) {
      setCurrentTextIndex((prevIndex) => prevIndex + 1);
      typedRefs[index + 1]?.start(); // 开始下一段文本的打印
    } else {
      underway(false);
    }
  };
  useEffect(() => {
    renderProps({
      handleStopAll,
      handleStartAll,
      handleResetAll,
      isEnd,
      setIsEnd,
    });
  }, [isEnd]);

  return (
    <>
      {data.map((textObj, index) => (
        <div
          className="document-root"
          key={index}
          style={{ display: currentTextIndex < index ? "none" : "block" }}>
          <ReactTyped
            key={textObj.key}
            startWhenVisible
            className={`intelligent-typed-${index}`}
            strings={textObj.strings}
            typeSpeed={40}
            typedRef={(ref) => {
              typedRefs[index] = ref;
              setTypedRefs([...typedRefs]);
            }} // 设置Typed实例的引用
            onComplete={() => {
              onComplete(index);
              setIsEnd(++index);
              // toolIndex.push(index);
              // setToolIndex([...toolIndex]);
            }} // 设置文本打印完成的回调
            startDelay={currentTextIndex < index ? 99999999999999 : 0} // 除了第一段文本，其他文本的打印都延迟到几乎无穷大，以便通过代码控制打印开始
            showCursor={false}
          />

          <div className="document-tool">
            {toolBtn.map((item, i) => (
              <Tooltip
                key={i}
                content={
                  <span
                    style={{
                      padding: 5,
                    }}>
                    {item.msg}
                  </span>
                }>
                <Image
                  onMouseEnter={() => {
                    setBtnIndex([index, i]);
                  }}
                  onMouseLeave={() => {
                    setBtnIndex([-1, -1]);
                  }}
                  style={{
                    marginLeft: 12,
                    display: item.disabled ? "none" : "inline-block",
                  }}
                  src={
                    btnIndex[0] == index && btnIndex[1] == i
                      ? item.activeIcon
                      : item.icon
                  }
                  width={16}
                  preview={false}
                  onClick={() => {
                    const stringsDom = document.querySelector(
                      `.intelligent-typed-${index}`,
                    ) as HTMLDivElement | null;
                    renderClick({
                      type: item.text,
                      text: stringsDom.innerText,
                      index,
                    });
                  }}
                />
              </Tooltip>
            ))}
          </div>
        </div>
      ))}
    </>
  );
};

export default DocumentText;
