/* eslint-disable no-unused-expressions */
import { useEffect, useMemo, useRef, useState } from "react";
import { createPlayer } from "./playerUtil";
import { Slider, Spin } from "@arco-design/web-react";
// import { IconSound, IconMute } from "@arco-design/web-react/icon";
import vodeoplayback from "@/assets/videoparsing/vodeoplayback.png";
// import videofullscreen from "@/assets/videoparsing/videoFullSCREEN.png";
import { Subscription } from "rxjs";
import debounce from "lodash.debounce";
import "./index.less";

const options = ["9:16", "16:9", "3:4", "4:3", "1:1"];

const isClipIdFocus = (timeline, clipId) => {
  const videoTracks = timeline?.timeline?.VideoTracks;
  if (!Array.isArray(videoTracks) || !clipId) {
    return false;
  }
  const trackClips = videoTracks.find(
    (item) => item.Type === "Subtitle",
  ).VideoTrackClips;
  if (!trackClips || trackClips.length === 0) {
    return false;
  }
  const index = trackClips.findIndex((item) => item.Id === clipId);

  return index > -1;
};

function Player(props) {
  const {
    previewInfo,
    PlayerFunRef = null,
    isDisabled = true,
    clipWatch = null,
    clipId,
    aspectRatio,
    autoPlay = false,
  } = props;
  const [size, setSize] = useState({ w: 360, h: 640 });
  const playerRef = useRef(null);
  const containerRef = useRef(null);
  const [loading, setLoading] = useState(false);
  // 播放状态和进度状态
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [duration, setDuration] = useState(0);
  const [isSeeking, setIsSeeking] = useState(false); // 标识是否正在拖拽进度条
  const [volume, setVolume] = useState(1); // 默认音量为100%
  const timer = useRef(null);
  const [previousVolume, setPreviousVolume] = useState(1); // 记录之前的音量，用于恢复
  const watcher = useRef({});
  const isMuted = volume === 0;

  // 动态加载脚本的函数
  const loadScript = (src) => {
    return new Promise((resolve, reject) => {
      // 检查脚本是否已经存在
      const existingScript = document.querySelector(`script[src="${src}"]`);
      if (existingScript) {
        resolve();
        return;
      }

      const script = document.createElement("script");
      script.src = src;
      script.async = true;

      script.onload = () => {
        console.log(`脚本 ${src} 加载完成`);
        resolve();
      };

      script.onerror = () => {
        console.error(`脚本 ${src} 加载失败`);
        reject(new Error(`脚本 ${src} 加载失败`));
      };

      document.head.appendChild(script);
    });
  };

  const loadAssetsAndInit = async () => {
    await Promise.all([
      loadScript(
        "https://g.alicdn.com/thor-server/video-editing-websdk/5.2.2/player.js",
      ),
    ]);
  };

  useEffect(() => {
    let isMounted = true; // 防止组件卸载后仍尝试更新
    if (!containerRef.current) return;
    if (!isMounted) return; // 组件已卸载，停止执行
    const initializePlayer = async () => {
      try {
        // 动态加载脚本
        await loadAssetsAndInit();

        // 确保 AliyunTimelinePlayer 已经存在
        if (!window.AliyunTimelinePlayer) {
          console.error("AliyunTimelinePlayer 未加载成功");
          return;
        }

        // 创建播放器
        const player = createPlayer({
          container: containerRef.current,
          minWidth: size.w,
        });
        playerRef.current = player;

        const subscription = new Subscription();
        const eventSubscription = player.event$.subscribe((event) => {
          switch (event.type) {
            case "play":
              setIsPlaying(true);
              break;
            case "pause":
              setIsPlaying(false);
              break;
            case "render":
              setProgress(player.currentTime);
              setDuration(player.duration);
              break;
            case "playerStateChange":
              if (player.currentTime === player.duration) {
                setIsPlaying(false);
              }
              break;
            default:
              break;
          }
        });

        subscription.add(eventSubscription);

        // 初始化音量
        player.volume = volume;

        // 保存订阅到引用中，以便在清理时使用
        playerRef.current.subscription = subscription;
      } catch (error) {
        console.error(
          "Failed to load player script or initialize player:",
          error,
        );
      }
    };

    initializePlayer();

    return () => {
      // 清理订阅和销毁播放器
      if (playerRef.current) {
        if (playerRef.current.subscription) {
          playerRef.current.subscription.unsubscribe();
        }
        playerRef.current.destroy();
      }
      isMounted = false;
    };
  }, [containerRef, size, isSeeking]);

  // 监听音量变化，更新播放器音量
  useEffect(() => {
    if (playerRef.current) {
      playerRef.current.volume = volume;
    }
  }, [volume]);

  const onSubmitPreview = useMemo(
    () =>
      debounce((preview, aspectRatio, size) => {
        setLoading(false);
        if (!preview?.timeline || !playerRef?.current?.setTimeline) {
          return;
        }

        playerRef.current.setTimeline(null);
        let timeline;
        try {
          timeline = preview.timeline;
        } catch (e) {
          alert("时间轴格式错误: " + e.message);
          return;
        }
        console.log(preview.timeline, 'preview.timeline')
        const timelineWrap = Object.assign(
          {
            Version: 1,
            AspectRatio: aspectRatio,
            From: "websdk",
            FECanvas: { Width: size.w, Height: size.h },
          },
          timeline,
        );

        Object.keys(watcher.current).forEach((v) => {
          const unwatch = watcher.current[v];
          if (unwatch) unwatch();
        });

        watcher.current = {};

        try {
          const parsedData = window.AliyunTimelinePlayer.parseTimeline(
            timelineWrap,
            {
              outputWidth: timelineWrap.FECanvas.Width,
              outputHeight: timelineWrap.FECanvas.Height,
            },
          );
          playerRef.current.setTimeline(parsedData.timeline);
          console.log("isClipIdFocus", isClipIdFocus(preview, clipId));

          if (isClipIdFocus(preview, clipId)) {
            setTimeout(() => {
              onFocusClip(clipId);
            }, 1000);
          }

          if (autoPlay) {
            setTimeout(() => {
              playerRef.current.play();
            }, 500);
          }
        } catch (error) {
          console.error("解析时间轴失败:", error);
        }
      }, 100),
    [],
  );

  const togglePlayPause = () => {
    if (isPlaying) {
      playerRef.current.pause();
    } else {
      if (progress === duration) {
        playerRef.current.currentTime = 0;
        playerRef.current.play();
      }
      playerRef.current.play();
    }
  };

  const onChangeAspectRatio = async () => {
    if (playerRef?.current) {
      playerRef?.current?.blurClip();
    }
    if (!aspectRatio) return;
    let nSize = { ...size };
    switch (aspectRatio) {
      case "9:16":
        nSize = { w: 360, h: 640 };
        break;
      // case "9:16-1.5x":
      //     nSize = { w: 360 * 1.5, h: 640 * 1.5 }
      //     break;
      case "16:9":
        nSize = { w: 640, h: 360 };
        break;
      case "3:4":
        nSize = { w: 360, h: 480 };
        break;
      case "4:3":
        nSize = { w: 480, h: 360 };
        break;
      case "1:1":
        nSize = { w: 480, h: 480 };
        break;
      default:
        break;
    }

    setSize(nSize);
    if (containerRef.current) {
      const scale = 360 / nSize.w;
      containerRef.current.style.setProperty("transform", `scale(${scale})`);
      setTimeout(() => {
        onSubmitPreview(previewInfo, aspectRatio, nSize);
      }, 1000);
    }
  };

  useEffect(() => {
    onChangeAspectRatio();
  }, [aspectRatio]);

  // 处理进度条变化
  const handleProgressChange = (value) => {
    setProgress(value);
  };

  const handleAfterChange = (value) => {
    setIsSeeking(false);
    playerRef.current.currentTime = value;
  };

  const handleBeforeChange = () => {
    setIsSeeking(true);
  };

  // 辅助函数，用于格式化时间
  function formatTime(seconds) {
    if (isNaN(seconds)) return "00:00";
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${padZero(secs)}`;
  }

  function padZero(num) {
    return num < 10 ? `0${num}` : num;
  }

  // 处理滑块变化
  const handleVolumeChange = (value) => {
    if (value < 0) value = 0;
    setVolume(value);
    if (value > 0) {
      setPreviousVolume(value);
    }
  };

  const onRemoveClip = (clipId) => {
    const unwatch = watcher.current[clipId];
    unwatch && unwatch();
    delete watcher.current[clipId];
  };

  const onFocusClip = (clipId) => {
    if (!Object.keys(watcher.current).some((v) => v === clipId)) {
      const unwatch = playerRef.current?.watchClip(
        Number(clipId),
        (vConfig) => {
          clipWatch && clipWatch(clipId, vConfig);
        },
      );
      watcher.current[clipId] = unwatch;
    }
    console.log("onrender");
    console.log(playerRef.current?.focusClip);
    console.log(clipId);
    playerRef.current?.focusClip(Number(clipId));
  };

  // 切换静音状态
  const toggleMute = () => {
    if (isMuted) {
      // 恢复之前的音量
      setVolume(previousVolume > 0 ? previousVolume : 1);
    } else {
      // 静音，并记录之前的音量
      setPreviousVolume(volume);
      setVolume(0);
    }
  };

  const audioControl = () => {
    return (
      <div className="audio-control-box">
        <Slider
          vertical
          value={volume}
          style={{ height: "100%" }}
          onChange={handleVolumeChange}
        />
      </div>
    );
  };

  useEffect(() => {
    if (timer.current) clearTimeout(timer.current);
    setLoading(true);
    timer.current = setTimeout(() => {
      onSubmitPreview(previewInfo, aspectRatio, size);
    }, 1000);
    return () => clearTimeout(timer.current);
  }, [previewInfo]);

  useEffect(() => {
    if (PlayerFunRef && PlayerFunRef.current) {
      PlayerFunRef.current = {
        aspectRatio,
        onChangeAspectRatio,
        aspectRatioOptions: options,
        onRemoveClip,
        onFocusClip,
      };
    }
  }, [PlayerFunRef]);

  useEffect(() => {
    if (clipId) {
      console.log("clipId变化触发", clipId);
      setTimeout(() => {
        onFocusClip(clipId);
      }, 300);
    } else {
      playerRef?.current?.blurClip();
    }
  }, [clipId]);

  return (
    <div className="Player">
      <Spin loading={loading} size={40}>
        <div className="preview">
          <div
            ref={containerRef}
            style={{ width: size.w, height: size.h }}
            onClick={() => {
              playerRef.current.pause();
            }}
          />
          {!isPlaying && !isDisabled ? (
            <img
              className="play-btn"
              src={vodeoplayback}
              onClick={togglePlayPause}
              alt=""
            />
          ) : (
            <></>
          )}
          {!isDisabled ? (
            <div className="video-controlsprogress-slide">
              <div
                className="video-progress-slider"
                style={{ position: "relative" }}>
                <Slider
                  max={duration}
                  tooltipVisible={false}
                  tooltip={{ visible: false }}
                  value={progress}
                  onChange={handleProgressChange}
                  onAfterChange={handleAfterChange}
                  onBeforeChange={handleBeforeChange}
                  style={{ width: 200 }}
                />
              </div>
              <div className="format-time">
                <div>
                  <span>{formatTime(progress)}</span>/
                  <span>{formatTime(duration)}</span>
                </div>
                <div className="format-tool"></div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </Spin>
    </div>
  );
}

export default Player;
