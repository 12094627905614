// extensions/CustomUndo.ts
import { Extension } from "@tiptap/core";
import { undoDepth } from "prosemirror-history";

const CustomUndo = Extension.create({
  name: "customUndo",
  addKeyboardShortcuts() {
    return {
      "Mod-z": (props) => {
        const { editor } = props;
        if (!event) return false; // TypeScript 安全检查
        // 阻止默认行为
        event.preventDefault();
        const { state } = editor.view;
        const { $from } = state.selection;
        // 检查当前光标是否在 bulletList 内
        const parentNode = $from.node($from.depth - 1);
        const isInBulletList =
          parentNode?.type.name === "bulletList" ||
          parentNode?.type.name === "listItem";
        if (isInBulletList && undoDepth(state) <= 1) {
          return true; // 阻止默认行为
        }
        // 如果不在 bulletList 内，允许默认撤销行为
        return false;
      },
    };
  },
});

export default CustomUndo;
