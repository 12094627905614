import { observer } from "mobx-react-lite";
import CheckBoxHoc from "../../CheckBoxHoc/CheckBoxHoc";
import "./LocalUploadCard.less";
import { IMaterialDetail } from "@/services/createSpace/graphQ/getSimpleMedia";
import ShowUploadNode, {
  IShowUploadNodeProps,
} from "../../UploadFileNode/ShowUploadNode/ShowUploadNode";
import UploadFileNode, {
  IUploadFileNodeProps,
} from "../../UploadFileNode/UploadFileNode";
import { deleteMaterial } from "@/services/createSpace/material";
import { Message } from "@arco-design/web-react";
export type LocalUploadCard = IShowUploadNodeProps &
  IUploadFileNodeProps & {
    onChange?: (e: boolean, item: IMaterialDetail) => void;
    checked?: boolean;
    item: IMaterialDetail;
    disOpearte?: boolean;
    filterCondition?: (item: IMaterialDetail) => boolean;
  };

export type LocalUploadCardProps = Omit<LocalUploadCard, "id">;
const LocalUploadCard: React.FC<LocalUploadCardProps> = observer((props) => {
  const {
    item,
    cover,
    type,
    name,
    state,
    progress,
    handleCloseBtnClick,
    handleCencelBtnClick,
    onChange,
    checked,
    style,
    error,
    filterCondition,
    disOpearte = false,
  } = props;

  const handleMaterialCloseBtnClick = async (id: number | string) => {
    try {
      await deleteMaterial([id as string], true);
      handleCloseBtnClick();
    } catch (e) {
      Message.error(e);
    }
  };

  return (
    <div style={{ ...style }}>
      {state === "success" ? (
        <div
          className="showUploadNoda-wrapper"
          style={{ marginRight: 16, marginBottom: 7 }}>
          {!disOpearte ? (
            <CheckBoxHoc
              style={{ borderRadius: 8 }}
              disabled={filterCondition ? filterCondition(item) : false}
              checked={checked}
              onCheckedChange={(e) => {
                onChange(e, item);
              }}>
              <ShowUploadNode
                handleCloseBtnClick={() => {
                  handleMaterialCloseBtnClick(item.id);
                }}
                id={item.id as string}
                cover={cover}
                onClick={() => {
                  onChange(!checked, item);
                }}
                type={type}
              />
            </CheckBoxHoc>
          ) : (
            <>
              <ShowUploadNode
                handleCloseBtnClick={() => {
                  handleMaterialCloseBtnClick(item.id);
                }}
                id={item.id as string}
                cover={cover}
                type={type}
              />
            </>
          )}
          <p style={{ textAlign: "center" }}>{name}</p>
        </div>
      ) : (
        <div style={{ marginRight: 16, marginBottom: 7 }}>
          <UploadFileNode
            coverImg={cover}
            state={state}
            type={type}
            name={name}
            error={error}
            progress={progress}
            handleCloseBtnClick={handleCloseBtnClick}
            handleCencelBtnClick={handleCencelBtnClick}
          />
        </div>
      )}
    </div>
  );
});

export default LocalUploadCard;
