/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from "react";
import "./index.less";
import Modals from "@/components/UI/Modals";
import {
  Button,
  Image,
  Input,
  Message,
  Steps,
  Divider,
} from "@arco-design/web-react";
import { addTeam } from "@/services/user/user";
import { setTokenToLocalStorage } from "@/utils/token";
import User from "@/stores/userStore";
import { info, bulb } from "@/assets/user-center";
import CreateContent from "./components/CreateContent";
import AttestationContent from "./components/AttestationContent";
import FinishContent from "./components/FinishContent";
import { IconClose } from "@arco-design/web-react/icon";
const Step = Steps.Step;
interface ICreateTeamProps {
  isTeam: boolean;
  visible: boolean;
  handleChangeMoadl: () => void;
}
const CreateTeam: React.FC<ICreateTeamProps> = ({
  visible,
  isTeam,
  handleChangeMoadl,
}) => {
  const [stepsCurrent, setStepsCurrent] = useState<number>(1);

  const stepsContent = (current) => {
    const actionMap = {
      1: (
        <CreateContent
          handleChange={handleChangeMoadl}
          setSteps={setStepsCurrent}
        />
      ),
      2: (
        <AttestationContent
          handleChange={handleChangeMoadl}
          setSteps={setStepsCurrent}
        />
      ),
      3: (
        <FinishContent
          handleChange={handleChangeMoadl}
          setSteps={setStepsCurrent}
        />
      ),
      // 可以继续添加更多映射
    };
    return actionMap[current];
  };
  useEffect(() => {
    return () => {
      setStepsCurrent(1);
    };
  }, []);
  return (
    <Modals
      // closeIcon={
      //   <IconClose
      //     onClick={() => {
      //       handleChangeMoadl();

      //       setStepsCurrent(1);
      //     }}
      //   />
      // }
      width={740}
      title="完善信息"
      titleIcon={<Image width={26} preview={false} src={info} />}
      visible={visible}>
      <div className="create-team-moadl">
        <div style={{ padding: "20px 0" }}>
          <Steps labelPlacement="vertical" current={stepsCurrent}>
            <Step title="创建团队" />
            <Step title="账户认证" />
            <Step title="开始使用" />
          </Steps>
        </div>
        {stepsContent(stepsCurrent)}
      </div>
    </Modals>
  );
};

export default CreateTeam;
