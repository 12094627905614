import React, { useState } from "react";
import HighLightText from "@/components/business/HighLightText";
import { Image, Button } from "@arco-design/web-react";
import { copyIcon, filmDditingIcon, videoIcon } from "@/assets/scriptimage";
import cs from "classnames";
import "./index.less";

interface ICardGroups {
  item: {
    title: string;
    content: string;
    hot_script_id: number;
    industry: string;
    product: string;
    category: string;
    id: number | string;
    used_count: number;
  };
  highLightText: string;
  index: number;
  handleClick?: (opt: any) => void;
}

const HotScriptCard: React.FC<ICardGroups> = (props) => {
  const {
    title,
    content,
    hot_script_id = 0,
    industry,
    product,
    category,
    used_count,
    id,
  } = props.item;
  const { highLightText, index, handleClick } = props;
  const [hoverCard, setHoverCard] = useState(-1);
  const contentList = JSON.parse(content);

  return (
    <HighLightText searchTerm={highLightText}>
      <div
        className="hot-card"
        onMouseEnter={() => {
          setHoverCard(index);
        }}
        onMouseLeave={() => {
          setHoverCard(-1);
        }}>
        <h1>
          {/* <UnderLineText>{title}</UnderLineText> */}
          {title}
        </h1>
        <div className="lable-box">
          <div
            className="card-lable"
            style={{
              display: industry ? "flex" : "none",
              background: "#E2ECFF",
            }}>
            {industry}
          </div>
          <div
            className="card-lable"
            style={{
              display: product ? "flex" : "none",
              background: "#FEF2E9",
            }}>
            {product}
          </div>
          <div
            className="card-lable"
            style={{
              display: category ? "flex" : "none",
              background: "#EBF5ED",
            }}>
            {category}
          </div>
        </div>

        <div
          className={cs("card-top", hoverCard === index ? "" : "hover-card")}>
          <div className="card-content">
            <h2>{contentList.section.map((v) => v.title).join("+")}</h2>
            <p>{contentList.section.map((v) => v.text).join(" ")}</p>
          </div>
        </div>
        <div className="card-bottom">
          <div className="card-footer">
            <div className="video-count">
              <Image src={videoIcon} width={20} preview={false} />
              <span>{used_count}</span>
            </div>
            <div className="card-btn">
              <Button
                className="card-filmDditing"
                onClick={() => {
                  handleClick(id);
                }}>
                导入
              </Button>
            </div>
          </div>
        </div>
      </div>
    </HighLightText>
  );
};

export default HotScriptCard;
