/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { useEffect, useState } from "react";
import "./index.less";
import Modals from "@/components/UI/Modals";
import { Button, Image, Input, Message, Select } from "@arco-design/web-react";
import imoirtscript from "@/assets/videoparsing/imoirtscript.png";
import { addTeam } from "@/services/user/user";
import { IconDoubleRight } from "@arco-design/web-react/icon";
import User from "@/stores/userStore";
import { observer } from "mobx-react";
const { Option } = Select;
interface IChangeTeamProps {
  visible: boolean;
  handleChangeMoadl: () => void;
  handleCreate?: () => void;
  handleClickConfirm: (opt: number) => void;
}
const ChangeTeam: React.FC<IChangeTeamProps> = ({
  visible,
  handleChangeMoadl,
  handleCreate,
  handleClickConfirm,
}) => {
  const [value, setValue] = useState(undefined);

  return (
    <Modals
      width={464}
      title="切换团队"
      cancelBtn={
        <Button
          onClick={() => {
            handleChangeMoadl();
            setValue(undefined);
          }}>
          取消
        </Button>
      }
      subMiteBtn={
        <Button
          disabled={!value}
          type="primary"
          onClick={() => {
            handleClickConfirm(value);
            setValue(undefined);
          }}>
          确认
        </Button>
      }
      titleIcon={<Image width={26} preview={false} src={imoirtscript} />}
      visible={visible}>
      <div className="user-create-team-moadl">
        <div className="team-moadl-msg">
          <h1>请选择要进入团队</h1>
        </div>
        <div className="team-moadl-select">
          <Select
            style={{ width: "100%" }}
            className="vlong-select-box"
            placeholder="请选择团队"
            allowClear
            onChange={(value) => {
              setValue(value);
            }}>
            {User.teamInfo.teamList.items &&
            User.teamInfo.teamList.items.length ? (
              User.teamInfo.teamList.items.map((option, index) => (
                <Option
                  key={index}
                  value={option.id}
                  disabled={option.status == 1}>
                  <p className="option-text">
                    {`${option.name}`}{" "}
                    {option.status == 1 ? (
                      <span className="block-up">停用</span>
                    ) : (
                      ""
                    )}
                  </p>
                </Option>
              ))
            ) : (
              <></>
            )}
          </Select>
        </div>
        <p
          className="not-in-team"
          style={{
            display: User.teamInfo.teamList?.team_id > 0 ? "none" : "block",
          }}
          onClick={() => {
            handleChangeMoadl();
            handleCreate();
          }}>
          暂不切换, 创建新团队
          <IconDoubleRight />
        </p>
      </div>
    </Modals>
  );
};

export default observer(ChangeTeam);
