export const MPEG = [
  "MP4",
  "TS",
  "3GP",
  "MPG",
  "MPEG",
  "MPE",
  "DAT",
  "VOB",
  "ASF",
];

export const AVI = ["AVI"];

export const WindowsMediaVideo = ["WMV", "ASF"];
export const FlashVideo = ["FLV", "F4V"];
// export const FlashVideo = ["F4V"];
export const RealVideo = ["RM", "RMVB"];
export const QuickTime = ["MOV"];
export const Matroska = ["MKV"];
// export const HLS = ["M3U8"];
export const videoOther = [
  "DV",
  "GIF",
  "M2T",
  "M4V",
  "MJ2",
  "MJPEG",
  "MTS",
  "OGG",
  "QT",
  "SWF",
  "WebM",
];

export const Mp3 = [
  "MP3",
  "WMA",
  "WAV",
  "AAC",
  "RA",
  "M4A",
  "FLAC",
  "APE",
  "AC3",
  "AMR",
  "CAF",
  "ACM",
];

export const picture = [
  "GIF",
   "WebP",
  "PNG",
  "JPG",
  "JPEG",
  //  "HEIC"
];

export const musicAllowType = [
  ...[Mp3.map((ext) => `.${ext.toLowerCase()}`).join(",")],
];

const allAllowType = [
  ...[MPEG.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[AVI.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[WindowsMediaVideo.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[FlashVideo.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[RealVideo.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[QuickTime.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[Matroska.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  // ...[HLS.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[videoOther.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[Mp3.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[picture.map((ext) => `.${ext.toLowerCase()}`).join(",")],
];

export const videAllowType = [
  ...[MPEG.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[AVI.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[WindowsMediaVideo.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[FlashVideo.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[RealVideo.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[QuickTime.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[Matroska.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  // ...[HLS.map((ext) => `.${ext.toLowerCase()}`).join(",")],
  ...[videoOther.map((ext) => `.${ext.toLowerCase()}`).join(",")],
];

export const pictureAllowType = [
  ...[picture.map((ext) => `.${ext.toLowerCase()}`).join(",")],
];

export default allAllowType;
