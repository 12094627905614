// import img from "@/assets/cut-mix-detail/video_img_block.png";
import { Tag } from "@arco-design/web-react";
import "./MaterialCard.less";
import { CardTypeEnum } from "@/pages/CreationSpace/components/MaterialCard/MaterialCard";

interface IMaterialCardProps {
  img: string;
  type?: CardTypeEnum;
  isImport?: boolean;
  onClick: () => void;
}

const SmallTag = (props: { text: string }) => {
  const { text } = props;
  return (
    <Tag
      color="blue"
      size="small"
      style={{
        background: "#FFFFFF",
        borderColor: "#165DFF",
        padding: "2px 4px",
        fontFamily: "PingFang SC, PingFang SC",
        borderRadius: 4,
        fontSize: 10,
        color: "#165DFF",
      }}>
      {text}
    </Tag>
  );
};

const toTypeGetTag = (type: CardTypeEnum) => {
  const tagMap = {
    [CardTypeEnum.MOVIE]: <SmallTag text="视频" />,
    [CardTypeEnum.MUSIC]: <SmallTag text="音频" />,
    [CardTypeEnum.PICTURE]: <SmallTag text="图片" />,
  };
  return tagMap[type];
};

const ImportTag = () => {
  return <Tag color={"#165dff"}>已导入</Tag>;
};

const MaterialCard: React.FC<IMaterialCardProps> = (props) => {
  const { img, type, isImport, onClick } = props;

  return (
    <div
      className="file-upload-small-material-card"
      onClick={() => {
        onClick();
      }}>
      <div className="file-upload-small-material-card-image">
        <div className="tag-wrapper">{toTypeGetTag(type)}</div>
        {isImport ? (
          <div className="import-tag-wrapper">
            <ImportTag />
          </div>
        ) : (
          <></>
        )}
        <img src={img} alt="图片描述" />
      </div>
    </div>
  );
};

export default MaterialCard;
