/* eslint-disable @typescript-eslint/no-empty-function */
/* eslint-disable @typescript-eslint/no-empty-interface */
import React, { Fragment, useEffect, useState } from "react";
import Modals from "@/components/UI/Modals";
import { Image, Input, Radio, Select } from "@arco-design/web-react";
import { add_msg_icon } from "@/assets/user-center";
import "./index.less";
import { IconClose, IconSearch } from "@arco-design/web-react/icon";
import {
  change_bottom_icon,
  change_top_icon,
  change_middle_icon,
} from "@/assets/creationscriptimage";
import { getProjectList } from "@/services/createSpace/project";
import { getScriptList } from "@/services/creativeScript/explosiveScript";
import Collapse from "@/components/UI/Collapse";
import { getScriptIndustry } from "@/services/creativeScript/explosiveScript";
import cs from "classnames";
import HotScriptCard from "./component/HotScriptCard";
import useNavProject from "@/useStore/createspace/useProject";
import useRequest from "@/requestHooks/mixCutDetail/useRequest";
import { IScriptItem, IScriptRequest } from "@/services/createSpace/script";
import ScriptItem from "@/pages/CreationSpace/CreativeSpace/CreativeContainer/components/Script/ScriptItem/ScriptItem";
import VLButton from "@/components/VLButton/VLButton";
import scriptClearImg from "@/assets/create-space/script-clear.png";
import {
  IGraphQScriptItem,
  getScriptList as getCreateScriptList,
} from "@/services/createSpace/graphQ/getScriptList";
import GridContainer from "@/components/UI/GridContainer";
import { setScriptCount } from "@/services/creativeScript/creationStores";
import HighlightSearch from "../HighLightText";
interface ISelectScriptProps {
  visible: boolean;
  handleChangeMoadl: () => void;
  handleImportScript?: (id: string | number, type: "custom" | "hot") => void;
  minHeight?: number;
}

interface ILibraryInfo {
  keyword: string;
  sort_field: string;
  sort_sc: "desc" | "asc";
  project_id: string;
}
const SelectScriptMoadl: React.FC<ISelectScriptProps> = ({
  minHeight,
  visible,
  handleChangeMoadl,
  handleImportScript,
}) => {
  const [radioValue, setRadioValue] = useState<string>("脚本库");
  const { projectList } = useNavProject();
  const [projectId, setProjectId] = useState(null);
  const [cardList, setCardList] = useState([]);
  const [highLightText, setHighLightText] = useState("");
  const [hotSeachValue, setHotSeachValue] = useState<string>("");
  const [searchObj, setSearchObj] = useState({
    keyword: "",
    scene_count: "",
    scenes_type: 0,
    industry: "",
    product: [],
    category: [],
    limit: 30,
    cursor: 0,
  });
  const { data, handleRequest, searchData } = useRequest<
    IGraphQScriptItem,
    IScriptRequest
  >({
    request: getCreateScriptList,
    initSearchData: {
      project_id: projectId,
      keyword: "",
      sort_sc: "desc",
      sort_field: "publish_date",
    },
    page: {
      page: 1,
      page_size: 10000,
    },
    manual: true,
  });
  const [isShowCollapse, setIsShowCollapse] = useState([]);
  const [scriptTags, setScriptTags] = useState<any>([]);
  const [libraryInfo, setLibraryInfo] = useState<ILibraryInfo>({
    keyword: "",
    sort_field: "publish_date",
    sort_sc: "desc",
    project_id: "",
  });
  const handleSearchChange = (value: string) => {
    setHotSeachValue(value);
    if (!value) {
      setHighLightText("");
    }
  };

  const getScriptTags = async () => {
    const res = await getScriptIndustry();
    if (res && res.items) {
      const nArr = res.items.map((v, i) => ({
        title: v.name,
        id: v.id,
        checked: false,
      }));
      nArr.unshift({
        title: "全部",
        id: "",
        checked: true,
      });
      setScriptTags(nArr);
    }
  };

  const getScriptPopFUn = async () => {
    const result = await getScriptList(searchObj);
    const res = result?.data;
    if (res && res.items && res.items.length) {
      setCardList(res.items);
    }
  };

  const handleChangeTags = (id) => {
    setScriptTags(
      scriptTags
        .map((v) => ({ ...v, checked: v.title == "全部" ? false : v.checked }))
        .map((v) => ({ ...v, checked: v.id == id ? !v.checked : v.checked })),
    );
  };
  const handleClearTags = () => {
    setScriptTags(
      scriptTags.map((v) => ({
        ...v,
        checked: v.title == "全部" ? true : false,
      })),
    );
  };

  useEffect(() => {
    setSearchObj({
      ...searchObj,
      industry: `${scriptTags.filter((v) => v.title != "全部" && v.checked).map((v) => v.title)}`,
    });
  }, [scriptTags]);

  useEffect(() => {
    getScriptPopFUn();
  }, [searchObj]);

  useEffect(() => {
    if (projectList.length > 0) {
      handleRequest({ ...searchData, project_id: projectList[0].id });
    }
  }, [projectList]);

  useEffect(() => {
    if (visible) {
      if (radioValue == "脚本库" && searchData.project_id) {
        handleRequest({ ...searchData });
      } else {
        getScriptTags();
      }
    }
  }, [visible, radioValue]);

  return (
    <Modals
      width={995}
      height={minHeight ? minHeight : 800}
      style={{ overflow: "auto" }}
      closeIcon={
        <IconClose
          onClick={() => {
            handleChangeMoadl();
          }}
        />
      }
      title="导入脚本"
      titleIcon={<Image width={26} preview={false} src={add_msg_icon} />}
      visible={visible}>
      <div className="user-create-team-moadl">
        <div className="radio-tool">
          <div>
            <Radio.Group
              type="button"
              name="position"
              value={radioValue}
              onChange={setRadioValue}
              options={["脚本库", "爆款脚本"]}></Radio.Group>
            <div>
              {radioValue == "爆款脚本" ? (
                <div>
                  <Input
                    style={{ width: 210, height: 38 }}
                    prefix={<IconSearch />}
                    value={hotSeachValue}
                    onChange={(e) => {
                      handleSearchChange(e);
                    }}
                    onPressEnter={() => {
                      setSearchObj({ ...searchObj, keyword: hotSeachValue });
                      setHighLightText(hotSeachValue);
                    }}
                    placeholder="请输入文件名关键字"
                  />
                </div>
              ) : (
                <div className="script-tool">
                  <Select
                    allowClear
                    style={{
                      height: 38,
                      width: 140,
                    }}
                    value={searchData.project_id || (projectList || [])[0]?.id}
                    options={projectList.map((v) => ({
                      label: v.name,
                      value: v.id,
                    }))}
                    placeholder="选择项目"
                    onChange={(e) => {
                      handleRequest({ ...searchData, project_id: e });
                    }}
                  />
                  <Input
                    style={{ width: 210, height: 38, marginLeft: 8 }}
                    prefix={<IconSearch />}
                    value={searchData.keyword}
                    onChange={(e) => {
                      handleRequest({ ...searchData, keyword: e });
                    }}
                    placeholder="请输入文件名关键字"
                  />
                  <div className="hybrid-select">
                    <Select
                      size="large"
                      allowClear
                      className={"create-space-search-filed-select"}
                      style={{
                        height: 38,
                        width: "200px",
                      }}
                      value={searchData.sort_field}
                      options={[
                        {
                          label: "按创建时间排序",
                          value: "@timestamp",
                        },
                        {
                          label: "按更新时间排序",
                          value: "publish_date",
                        },
                      ]}
                      placeholder="按更新时间"
                      onChange={(e) => {
                        handleRequest({ ...searchData, sort_field: e });
                      }}
                    />
                    <div className="sc-middle"></div>
                    <div
                      className={`sc-wrapper`}
                      onClick={() => {
                        handleRequest({
                          ...searchData,
                          sort_sc:
                            searchData.sort_sc === "desc" ? "desc" : "asc",
                        });
                      }}>
                      <img
                        width={16}
                        src={
                          searchData.sort_sc === "desc"
                            ? change_middle_icon
                            : searchData.sort_sc === "asc"
                              ? change_bottom_icon
                              : change_top_icon
                        }
                        alt=""
                      />
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          {radioValue == "脚本库" ? (
            <>
              {!data || data.length === 0 ? (
                <>
                  <div className="custom-script-import-empty">
                    <div>
                      <img src={scriptClearImg} alt="" style={{ width: 200 }} />
                      <p className="empty-tip">暂无数据</p>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  {(data || []).map((item) => {
                    return (
                      <Fragment key={item.id}>
                        <ScriptItem
                          highTextKeyWord={searchData.keyword}
                          style={{
                            width: "calc(100% - 40px)",
                            marginBottom: 20,
                            marginTop: 20,
                            marginLeft: 0,
                          }}
                          useAvatorList={[
                            {
                              avator: item.creator_avatar,
                              name: item.creator,
                            },
                          ]}
                          isBatchOperable={false}
                          actionBarNode={
                            <>
                              <VLButton
                                type="primary"
                                onClick={() => {
                                  if (handleImportScript) {
                                    handleImportScript(
                                      item.id,
                                      radioValue === "脚本库"
                                        ? "custom"
                                        : "hot",
                                    );
                                  }
                                }}>
                                导入
                              </VLButton>
                            </>
                          }
                          data={item}
                          handleBatchSelectChange={(e, id) => {
                            // handleScriptItemCheckedChange(e, id as number);
                            console.log(e, id);
                          }}
                        />
                      </Fragment>
                    );
                  })}
                </>
              )}
            </>
          ) : (
            <></>
          )}
          {radioValue == "爆款脚本" ? (
            <Collapse isShow={isShowCollapse} hiddenHeight={60}>
              <div className="row-tag">
                {scriptTags.map((item, index) => (
                  <div
                    key={item.title}
                    className={cs(
                      "tag-button",
                      item.checked ? "active-tag-button" : "",
                    )}
                    onClick={() => {
                      if (item.title == "全部") {
                        handleClearTags();
                      } else {
                        handleChangeTags(item.id);
                      }
                    }}>
                    {item.title}
                  </div>
                ))}
              </div>
            </Collapse>
          ) : (
            <></>
          )}
        </div>
        {radioValue == "脚本库" ? (
          <div className="script-library">
            {/* {ScriptList.map((item) => {
              return (
                <div key={item.id}>
                  <ScriptItem
                    data={item}
                    style={{
                      marginTop: "12px",
                      width: "921px",
                      boxSizing: "border-box",
                      height: "166px",
                    }}
                    actionBarNode={
                      <VoLongButton type="primary">导入</VoLongButton>
                    }></ScriptItem>
                </div>
              );
            })} */}
          </div>
        ) : (
          <div className="hot-script">
            <div className="hot-card-root">
              <GridContainer dataLength={cardList.length} boxGap={12}>
                {cardList.map((v, index) => (
                  <GridContainer.Item key={index}>
                    <HotScriptCard
                      key={index}
                      item={{
                        ...v,
                      }}
                      highLightText={highLightText}
                      index={index}
                      handleClick={async (opt) => {
                        if (handleImportScript) {
                          handleImportScript(
                            opt,
                            radioValue === "脚本库" ? "custom" : "hot",
                          );
                        }
                        await setScriptCount({
                          id: opt,
                          type: 2,
                        });
                      }}
                    />
                  </GridContainer.Item>
                ))}
              </GridContainer>
            </div>
          </div>
        )}
      </div>
    </Modals>
  );
};

export default SelectScriptMoadl;
